import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { palettes } from '../../../../config';
import { setEventProduct } from '../../../../redux/eventProductDetail';
import { twoDp } from '../../../../utils/twoDP';
import { EventProductCardProps } from '../../event-section-mobile/EventProuctCard';

const EventProductCard = ({
	shortDesc,
	price,
	imageLink: uri,
	productName,
}: EventProductCardProps) => {
	const dispatch = useDispatch();
	return (
		<View style={styles.flex1}>
			{/* <Pressable
				onPress={() =>
					dispatch(
						setEventProduct({
							desc: '12 Colors Velvet Matte Lipsticks Pencil Natural Waterproof',
							price: 328.85,
						})
					)
				}
			> */}
			<View style={styles.container}>
				<Image style={styles.image} source={{ uri }} />
				<View style={styles.info}>
					<Text style={styles.text}>{productName}</Text>
					<Text style={styles.price}>
						{getSymbolFromCurrency('eur')}
						{twoDp(price)}
					</Text>
				</View>
			</View>
			{/* </Pressable> */}
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		// flex: 1,
		marginBottom: 15,
	},
	container: {
		flexDirection: 'row',
		backgroundColor: palettes.light[0],
		borderRadius: 6,
	},
	image: {
		height: 78,
		width: 78,
		marginRight: '12px',
		borderRadius: 6,
	},
	info: {
		justifyContent: 'space-around',
		width: '67%',
		fontSize: 14,
		lineHeight: 12,
	},
	text: {},
	price: {
		fontWeight: 'bold',
		color: '#10162',
	},
});

export default EventProductCard;
