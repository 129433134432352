import React, { useEffect, useState } from 'react';
import { DropzoneProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button, Input, Label, Upload } from '../..';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import useGetMedia from '../../../api/common/useGetMedia';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	removeFromEventMedia,
	setEventMedia,
	setSocialStreamKeys,
} from '../../../redux/createEventSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import PlusIcon from '../../home/PlusIcon';
import { ImageContainer } from './ImageContainer';
import { palettes } from '../../../config';
import { Popable } from 'react-native-popable';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import {
	FACEBOOK_CLIENT_ID,
	FACEBOOK_CONFIG_ID,
	REDIRECT_URI_EVENT,
} from '../../../constants/KEYS';
import { SignupCompanyButton } from '../../auth/login/signup-company-buttons/SignupCompanyButton';
import { SIGN_UP_BUTTONS } from '../../auth/login/signup-company-buttons/SignupCompanyButtons';
import axios from 'axios';
import { FacebookIcon } from '../../common/icons';

const EventUploadForm = () => {
	const [images, setImages] = useState<TMedia[]>([]);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [socialStreamKey, setSocialStreamKey] = useState<TSocialStreamKey>(
		{} as TSocialStreamKey
	);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const event = useAppSelector(state => state.getEvent.event);
	const isEditEvent = useAppSelector(state => state.getEvent.isEditEvent);
	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);

	const onCompletedUploadingMedia = (data: TMediaResponse) => {
		if (data?.createMedia.data && data.createMedia.success) {
			setAlert(t('Image uploaded successfully'), 'normal');
			dispatch(setEventMedia(data.createMedia.data._id));
			setImages([...images, data.createMedia.data]);
		} else {
			setAlert(t('Image uploading failed'), 'danger');
		}
	};

	const { uploadMedia, loading } = useCreateMedia({
		onCompleted: onCompletedUploadingMedia,
	});

	const { executeMediaQuery, data: mediaData } = useGetMedia();

	const onRemoveMedia = (id: string) => {
		dispatch(removeFromEventMedia(id));
		setImages(prev => prev.filter(media => media._id !== id));
	};
	useEffect(() => {
		if (isEditEvent)
			if (event?.eventMedia?.length) {
				event.eventMedia.forEach((_id: string) => {
					executeMediaQuery({
						_id,
					});
				});
			}
	}, []);

	const eventMedia = useAppSelector(state => state.createEvent.eventMedia);

	// 1444695859769052

	// useEffect(() => {
	// 	if (isEditEvent) {
	// 		dispatch(setEventMedia(data.createMedia.data._id));
	// 	}
	// }, []);

	useEffect(() => {
		// Load the Facebook SDK script
		if (!window) return;
		(function (d, s, id) {
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			const js = d.createElement(s);
			js.id = id;
			(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
			(fjs as any).parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
		// Initialize the Facebook SDK after it has loaded
		(window as any).fbAsyncInit = () => {
			(window as any).FB.init({
				appId: FACEBOOK_CLIENT_ID, // Replace with your Facebook App ID
				cookie: true,
				xfbml: true,
				version: 'v20.0',
				config_id: FACEBOOK_CONFIG_ID, // Replace with your config_id
			});
		};
	}, []);

	useEffect(() => {
		const image = mediaData?.getMedia?.data;
		if (image) setImages([...images, image]);
	}, [mediaData]);

	const onDrop: DropzoneProps['onDrop'] = (acceptedFiles, _, __) => {
		setAlert(t('Uploading Image'), 'warning');
		uploadMedia(acceptedFiles[0], 'EVENT', `Product Image ${Date.now}`);
	};

	const onPressNextButton = () => {
		dispatch(setSocialStreamKeys(socialStreamKey));
		goToNextStepScreen();
	};

	const facebookCallback = (response: any) => {
		const profileObj: TGoogleLoginData = response;

		console.log({ profileObj });

		axios
			.post(
				`https://graph.facebook.com/v3.3/${response?.id}/live_videos`,
				{
					status: 'LIVE_NOW',
					access_token: response?.accessToken,
				}
			)
			.then(response => {
				console.log('Live video started successfully:', response.data);
				setSocialStreamKey({
					...socialStreamKey,
					facebookStreamKey: response?.data?.secure_stream_url,
				});
			})
			.catch(error => {
				console.error('Error starting live video:', error);
			});
	};

	return (
		<View>
			<Label label={t('Add image or video to highlight your event')} />
			<View style={styleRowContainer()}>
				<Upload
					containerStyles={{
						marginRight: getResponsiveStyle(16),
						width: getResponsiveStyle(128, 'dimensions'),
						height: getResponsiveStyle(136, 'dimensions'),
						marginBottom: getResponsiveStyle(24),
					}}
					onDrop={onDrop}
					uploadIcon={<PlusIcon height={14} width={14} />}
					accept={'image/*,video/mp4'}
					hint={t('Add image or video')}
					loading={loading}
				/>
				{images.length > 0 &&
					images.map(image => (
						<ImageContainer
							key={image.src}
							src={image.src}
							alt={image.alt}
							containerStyles={[
								styles.imageContainerStyles,
								styleImageContainer(),
							]}
							id={image._id}
							onRemove={onRemoveMedia}
						/>
					))}
			</View>

			<View>
				<View>
					<Label
						label={t(
							'Please enter your stream key for Facebook and/or YouTube in the fields below to broadcast simultaneously on both platforms.'
						)}
					/>
				</View>
				{/* <FacebookLogin
					// appId="734818670865062"
					appId={FACEBOOK_CLIENT_ID}
					fields="name,email,picture"
					callback={facebookCallback}
					// redirectUri={REDIRECT_URI}
					cookie={true}
					xfbml={true}
					version={'3.2'}
					autoLoad={false}
					isMobile={true}
					disableMobileRedirect={true}
					render={(renderProps: any) => {
						return ( */}
				<Input
					label={t('Facebook Stream Key')}
					value={socialStreamKey?.facebookStreamKey}
					containerStyles={[styles.flex1]}
					// editable={false}
					onChangeText={text => {
						setSocialStreamKey({
							...socialStreamKey,
							facebookStreamKey: text,
						});
					}}
					// decoration={
					// 	<Pressable
					// 		style={styles.instruction}
					// 		onPress={() => {
					// 			window?.FB?.login(
					// 				(response: any) => {
					// 					console.log({
					// 						facebookResponse:
					// 							response,
					// 					});

					// 					facebookCallback(
					// 						response?.authResponse
					// 					);
					// 				},
					// 				{
					// 					config_id:
					// 						FACEBOOK_CONFIG_ID
					// 				}
					// 			);
					// 			// renderProps?.onClick();
					// 		}}
					// 	>
					// 		<Text>
					// 			{t(
					// 				'Click to get Facebook Stream URL'
					// 			)}
					// 		</Text>
					// 		<FacebookIcon
					// 			height={getResponsiveStyle(
					// 				12,
					// 				'dimensions'
					// 			)}
					// 			width={getResponsiveStyle(
					// 				12,
					// 				'dimensions'
					// 			)}
					// 		/>
					// 	</Pressable>
					// }
					decoration={
						<Popable
							backgroundColor="#fff"
							style={styles.instructionContainer}
							content={
								<>
									<View
										style={[
											styles.instructionTextContainer,
											{
												width: isLessThanDesktopBase
													? '70%'
													: '100%',
											},
										]}
									>
										<Text style={styles.instructionText}>
											{t(
												"You can find your Facebook stream key by setting up your live session on Facebook. Fill in your live event details, click 'Next', then go to 'Settings' - 'Streams' under 'Integrated Live Video'. Copy the code starting with 'FB' and paste it here."
											)}
										</Text>
									</View>
								</>
							}
						>
							<View style={styles.instruction}>
								<Text>{t('Where to find')}?</Text>
							</View>
						</Popable>
					}
				/>
				{/* );
					}}
				/> */}
				<Input
					label={t('Youtube Stream Key')}
					value={socialStreamKey?.youtubeStreamKey}
					containerStyles={[styles.flex1]}
					onChangeText={text => {
						setSocialStreamKey({
							...socialStreamKey,
							youtubeStreamKey: text,
						});
					}}
					decoration={
						<Popable
							style={styles.instructionContainer}
							backgroundColor="#fff"
							content={
								<>
									<View
										style={[
											styles.instructionTextContainer,
											{
												width: isLessThanDesktopBase
													? '70%'
													: '100%',
											},
										]}
									>
										<Text style={styles.instructionText}>
											{t(
												"To get your YouTube stream key, go to your YouTube Studio, click on 'Go Live', then 'Stream' in the 'Stream Settings'. Copy the displayed stream key and paste it here."
											)}
										</Text>
									</View>
								</>
							}
						>
							<View style={styles.instruction}>
								<Text>{t('Where to find')}?</Text>
							</View>
						</Popable>
					}
				// onBlur={handleBlur('title')}
				// error={
				// 	touched.title && errors.title
				// 		? errors.title
				// 		: ''
				// }
				/>
			</View>
			<Button
				title={t('Next')}
				variant={loading ? 'disabled' : 'primary'}
				size="lg"
				onPress={onPressNextButton}
				outerContainerProps={{
					style: styleButtonOuterProps(),
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	imageContainerStyles: {
		minWidth: 144,
		flex: 1,
	},
	instruction: {
		padding: 5,
		backgroundColor: palettes?.grey[7],
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		// justifyContent: "center",
		borderRadius: 6,
	},
	instructionContainer: {
		width: 400,
	},
	instructionTextContainer: {
		backgroundColor: palettes?.dark[0],
		borderRadius: 6,
		borderWidth: 1,
	},
	instructionText: {
		color: palettes?.light[0],
	},
	flex1: {
		flex: 1,
	},
	description: {
		paddingVertical: 20,
	},
});

export default EventUploadForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: getResponsiveStyle(24),
		marginRight: getResponsiveStyle(-16),
	} as TStyle;
};
function styleButtonOuterProps() {
	return {
		marginTop: getResponsiveStyle(46),
	};
}

function styleImageContainer() {
	return {
		marginRight: getResponsiveStyle(16),
	};
}

function styleAddressContainer() {
	return { marginBottom: getResponsiveStyle(24) };
}

function styleAddressInput1() {
	return { marginBottom: getResponsiveStyle(16) };
}

function styleDescription() {
	return {
		height: getResponsiveStyle(80, 'dimensions'),
	};
}
