import { useMutation, useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import useGetAffiliatedBrandEvents, {
	useGetAffiliatedBrands,
} from '../api/brands/useGetAffiliatedBrands';
import { useGetBrandStats } from '../api/brands/useGetBrandStats';
import { useGetSingleBrandLazy } from '../api/brands/useGetSingleBrand';
import { useGetAllEventsLazy } from '../api/events/useGetAllEventsLazy';
import {
	AllProducts,
	CompanyInfo,
	Loader,
	LoadMore,
	PageDetailsHero,
	StarterKit,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GET_BRAND_STATS } from '../graphql/brands/queries';
import { FOLLOW_BRAND, UNFOLLOW_BRAND } from '../graphql/follow/mutations';
import { IS_FOLLOWING } from '../graphql/network/queries';
import { usePagination } from '../hooks/useCurrentForPagination';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TUseRoute } from '../types/exportedTypes';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { setCurrentScreen } from '../redux/uiSlice';

const BrandDetailsScreen = () => {
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();

	const { typeOfAccount } = useAppSelector(state => ({
		typeOfAccount: state.auth.loggedInUserDetails?.typeOfAccount,
	}));
	const TABS = [
		{ label: t('Starter Kit'), value: 'Starter Kit' },
		{ label: t('Products'), value: 'Products' },
		{ label: t('Info'), value: 'Info' },
	];
	const routes = useRoute<TUseRoute<'BrandDetails'>>();
	const { _id, brandDetails: brandDetailsPassed } = routes.params;
	const LIMIT = 10;
	const [current, increaseCurrentByOne] = usePagination();

	const [selectedTab, setSelectedTab] = useState(
		typeOfAccount === 'SELLER' ? TABS[0].value : TABS[1].value
	);
	const [brandDetails, setBrandDetails] = useState<TGetAllBrandsData>();
	const [isBrandFollowed, setIsBrandFollowed] = useState(false);
	const [brandsAffiliatedEvents, setBrandsAffiliatedEvents] = useState<
		TGetAllEventsData[]
	>([]);

	const { getBrandStats, data: brandStats } = useGetBrandStats({});

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/brand-details'));
	}, []);

	useEffect(() => {
		if (_id) {
			getBrandStats([_id]);
		}
	}, [_id]);

	const brandStatsArray = brandStats?.getBrandStats?.data?.data[0]?.followers;

	console.log({
		brandDetailsPassed: brandDetailsPassed?.products,
		brandDetails,
	});

	const STATS = [
		{
			label: t('Guests'),
			value: brandStatsArray?.guestCount,
		},
		{
			label: t('Sellers'),
			value: brandStatsArray?.sellersCount,
		},
		{
			label: t('Hosts'),
			value: brandStatsArray?.hostCount,
		},
	];

	const onPressTab = (tab: string) => {
		setSelectedTab(tab);
	};

	/*************** API *************** */
	// useEffect(() => {
	// 	if (_id) {
	// 		getAllEvents({
	// 			variables: {
	// 				value: JSON.stringify({
	// 					affiliatedBrands: {
	// 						'$elemMatch': {
	// 							'$eq': _id,
	// 						},
	// 					},
	// 				}),
	// 			},
	// 		});
	// 	}
	// }, [_id]);

	const onCompleted: TOnCompleteApiCall<
		TGetAllAffiliatedBrandEventsResponse
	> = data => {
		if (
			data.getEventsAffiliatedWithBrand?.success &&
			data.getEventsAffiliatedWithBrand.data
		) {
			setBrandsAffiliatedEvents(
				data.getEventsAffiliatedWithBrand.data.data
			);
		}
	};

	const { executeAffiliatedBrandEvents } = useGetAffiliatedBrandEvents({
		onCompleted,
	});

	// const [getAllEvents] = useGetAllEventsLazy({ onCompleted });
	/*************** API *************** */

	useEffect(() => {
		setBrandDetails(brandDetailsPassed);
	}, [_id]);

	useEffect(() => {
		executeAffiliatedBrandEvents({ brandId: _id });
	}, []);

	/****************** START - API - getSingleBrand ********************** */
	useEffect(() => {
		if (_id) {
			//DONT REMOVE THIS FAULTY QUERY. IT IS HERE FOR A REASON. React apollo wont let you refetch without calling the initial query
			getSingleBrand();
			//DONT REMOVE THIS FAULTY QUERY. IT IS HERE FOR A REASON. React apollo wont let you refetch without calling the initial query.If you find a work around , you can fix
			const activeLength = brandDetailsPassed?.products?.data?.length;

			!!brandDetailsPassed && activeLength && activeLength > 1
				? setBrandDetails(brandDetailsPassed)
				: getSingleBrand(_id, current, LIMIT);
		}
	}, [_id, brandDetailsPassed]);

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand && data.getBrand.success && data.getBrand.data) {
			let productArr: TGetAllProductsDataPaginated =
				data?.getBrand?.data?.products;

			if (
				brandDetails?.products?.data[0]?.name ===
				productArr.data[0]?.name
			)
				return;
			brandDetails
				? setBrandDetails({
						...brandDetails,
						products: {
							total:
								data?.getBrand?.data?.products?.total ??
								brandDetails?.products?.total,
							data: [
								...brandDetails?.products?.data,
								...productArr.data,
							],
						},
				  })
				: setBrandDetails(data.getBrand.data);
		}
	};
	const { getSingleBrand, loading, refetch, networkStatus } =
		useGetSingleBrandLazy({
			onCompleted: onCompletedGetSingleBrandDetails,
		});
	/****************** END - API - getSingleBrand ********************** */

	/****************** START - API - follow ********************** */

	const [followAccount, { loading: loadingFollow }] = useMutation<
		TFollowBrandResponse,
		TFollowInputs
	>(FOLLOW_BRAND, {
		onCompleted: data => {
			if (
				data.FollowBrand.success &&
				data.FollowBrand.statusCode === 200
			) {
				setIsBrandFollowed(true);
			}
		},
	});

	/****************** End - API - follow ************************ */

	/******************** START - API - isFollowing ***********************/

	useQuery<TIsFollowingResponse, TIsFollowingInputs>(IS_FOLLOWING, {
		variables: {
			args: {
				followedIds: [_id],
				type: 'BRAND',
			},
		},
		onCompleted: data => {
			if (data.IsFollowing.success && data.IsFollowing.data) {
				data.IsFollowing.data.data[0].isFollowing
					? setIsBrandFollowed(true)
					: setIsBrandFollowed(false);
			}
		},
	});

	/******************** END  -  API - isFollowing ***********************/

	/******************** START - API - unfollow ***********************/
	const [unFollowAccount, { loading: loadingUnfollow }] = useMutation<
		TUnfollowBrandResponse,
		TUnfollowInputs
	>(UNFOLLOW_BRAND, {
		onCompleted: data => {
			if (data && data.UnFollowBrand && data.UnFollowBrand.success) {
				setIsBrandFollowed(false);
			}
		},
	});

	/******************** END  -  API - unfollow ***********************/
	const onPressViewMore = () => {
		refetch({
			_id,
			productCurrent: increaseCurrentByOne(),
			productLimit: LIMIT,
			productFind: { publishedStatus: 'ACTIVE' },
		});
	};

	// const ids = useAppSelector(state => state.user.followedIds);
	const followUnfollowMutationFunction = (id: string) => {
		const variables = {
			args: {
				followedId: id,
			},
		};

		const refetchQueries = [IS_FOLLOWING, GET_BRAND_STATS];

		isBrandFollowed
			? unFollowAccount({
					variables,
					awaitRefetchQueries: true,
					refetchQueries,
			  })
			: followAccount({
					variables,
					awaitRefetchQueries: true,
					refetchQueries,
			  });
	};

	const loadingState = useMemo(
		() => getSingleLoadingState(loadingFollow, loadingUnfollow),
		[loadingFollow, loadingUnfollow]
	);

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{loading && !brandDetails ? (
				<Loader />
			) : !!brandDetails ? (
				<View>
					<PageDetailsHero
						city={brandDetails?.address?.city}
						countryCode={brandDetails?.address?.countryCode}
						name={brandDetails?.name}
						onPressTab={onPressTab}
						rating={Number(brandDetails?.rating)}
						_id={brandDetails?._id ?? _id}
						followUser={followUnfollowMutationFunction}
						followLoading={loadingState}
						isUserFollowed={isBrandFollowed}
						selectedTab={selectedTab}
						stats={STATS}
						tabs={
							typeOfAccount === 'SELLER'
								? TABS
								: TABS.filter((_, index) => index !== 0)
						}
						profileImage={brandDetails?.profileImage}
					/>
					<View>
						{selectedTab === TABS[0].value && (
							<StarterKit brandId={_id} />
						)}
						{selectedTab === TABS[1].value && (
							<>
								<AllProducts
									products={brandDetails?.products?.data}
									onBrandDetailsScreen={true}
									brand={brandDetails?._id}
								/>
								{brandDetails?.products?.total >
									brandDetails?.products?.data?.length && (
									<LoadMore
										loading={networkStatus === 2}
										onClick={onPressViewMore}
									/>
								)}
							</>
						)}
						{selectedTab === TABS[2].value && (
							<CompanyInfo
								description={brandDetails?.description}
								events={brandsAffiliatedEvents}
							/>
						)}
					</View>
				</View>
			) : (
				<Loader />
			)}
		</DesktopTemplate>
	);
};

export default BrandDetailsScreen;
