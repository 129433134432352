import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { manipulateString } from '../../../utils/manipulateString';

type Props = {
	typeOfAccount: TTypeOfAccount;
};

export const HostSellerLabel = ({ typeOfAccount }: Props) => {
	const { t } = useTranslation();
	return (
		<Text
			style={{
				fontSize: getResponsiveStyle(12, 'font'),
				lineHeight: getResponsiveStyle(14, 'font'),
				color: 'white',
			}}
		>
			{manipulateString.capitalize(typeOfAccount)} | {t('Private')}
		</Text>
	);
};
