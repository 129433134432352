import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TCreateEventInputs = {
	name: '',
	description: '',
	eventLocation: {
		address1: '',
		address2: '',
		city: '',
		countryCode: '',
		countryLabel: '',
		geoCodedAddress: '',
		zipCode: '',
	},
	socialStreamKey: {
		youtubeStreamKey: '',
		facebookStreamKey: '',
	},
	typeOfEvent: 'VIRTUAL',
	eventMedia: [],
	affiliatedBrands: [],
	affiliatedHosts: [],
	invitedPeople: [],
	catchmentAreas: [],
	startingEventDateTime: '',
	liveShopping: undefined,
	isHostRequired: false,
	// isInviteAllGuest: false,
};

const createEventSlice = createSlice({
	name: 'createEvent',
	initialState,
	reducers: {
		setTypeOfEvent(state, action: PayloadAction<TTypeOfEvent>) {
			state.typeOfEvent = action.payload;
		},
		setEventDetails(
			state,
			action: PayloadAction<{
				name: string;
				description: string;
			}>
		) {
			const { description, name } = action.payload;
			state.name = name;
			state.description = description;
		},
		setCatchmentAreas(state, action: PayloadAction<CatchmentArea[]>) {
			state.catchmentAreas = action.payload;
		},
		setLiveShopping(state, action: PayloadAction<boolean>) {
			state.liveShopping = action.payload;
		},
		setEventTimeAndDuration(state, action: PayloadAction<string>) {
			state.startingEventDateTime = action.payload;
		},
		setEventLocation(state, action: PayloadAction<TCommonAddressInput>) {
			state.eventLocation = action.payload;
		},
		setSocialStreamKeys(state, action: PayloadAction<TSocialStreamKey>) {
			state.socialStreamKey = action.payload;
		},
		resetInvitedPeople(state, action: PayloadAction<[]>) {
			state.invitedPeople = action.payload;
		},
		setInvitedPeople(state, action: PayloadAction<string>) {
			state.invitedPeople = [
				...(state.invitedPeople ?? []),
				action.payload,
			];
		},
		removeFromInvitedPeople(state, action: PayloadAction<string>) {
			state.invitedPeople = state.invitedPeople?.filter(
				person => person !== action.payload
			);
		},
		resetEventMedia(state, action: PayloadAction<[]>) {
			state.eventMedia = action.payload;
		},
		setEventMedia(state, action: PayloadAction<string>) {
			state.eventMedia = [...state.eventMedia, action.payload];
		},
		removeFromEventMedia(state, action: PayloadAction<string>) {
			state.eventMedia = state.eventMedia.filter(
				media => media !== action.payload
			);
		},
		resetAffiliatedHosts(state, action: PayloadAction<[]>) {
			state.affiliatedHosts = action.payload;
		},
		setAffiliatedHosts(state, action: PayloadAction<string>) {
			state.affiliatedHosts = [...state.affiliatedHosts, action.payload];
		},
		removeFromAffiliatedHosts(state, action: PayloadAction<string>) {
			state.affiliatedHosts = state.affiliatedHosts?.filter(
				person => person !== action.payload
			);
		},
		resetAffiliatedBrands(state, action: PayloadAction<[]>) {
			state.affiliatedBrands = action.payload;
		},
		setaffiliatedBrands(state, action: PayloadAction<string>) {
			state.affiliatedBrands = [
				// ...state.affiliatedBrands,
				action.payload,
			];
		},
		removeaffiliatedBrands(state, action: PayloadAction<string>) {
			state.affiliatedBrands = [];
			// state.affiliatedBrands.filter(
			// 	brand => brand !== action.payload
			// ) ?? [];
		},
		resetCreateEventSlice() {
			return initialState;
		},
	},
});

export default createEventSlice.reducer;
export const {
	setTypeOfEvent,
	setEventDetails,
	setEventTimeAndDuration,
	setEventLocation,
	setSocialStreamKeys,
	setInvitedPeople,
	setCatchmentAreas,
	setEventMedia,
	setLiveShopping,
	setAffiliatedHosts,
	setaffiliatedBrands,
	removeaffiliatedBrands,
	resetInvitedPeople,
	resetEventMedia,
	resetAffiliatedHosts,
	resetAffiliatedBrands,
	resetCreateEventSlice,
	removeFromAffiliatedHosts,
	removeFromInvitedPeople,
	removeFromEventMedia,
} = createEventSlice.actions;
