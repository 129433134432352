import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { i18n } from '../../../i18n';

type Props = {
	labelText: string;
	labelLink: string;
	selectText: string;
	onSelect: (view: string) => void;
	isMobile?: boolean;
};

const CustomLabel = ({
	labelText,
	labelLink,
	selectText,
	onSelect,
	isMobile,
}: Props) => {
	const { t } = useTranslation();
	return (
		<View style={[styles.label, isMobile && styles.labelMobile]}>
			<Text
				style={[styles.labelText, isMobile && styles.labelTextMobile]}
			>
				{t(labelText)}
			</Text>
			<Pressable key={selectText} onPress={() => onSelect(selectText)}>
				<Text
					style={[
						styles.labelLink,
						isMobile && styles.labelLinkMobile,
						labelLink === 'Close' && { color: '#F96152' },
					]}
				>
					{t(labelLink)}
				</Text>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	labelText: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 18,
		lineHeight: 14,
		color: palettes.dark[0],
	},
	labelTextMobile: {
		color: palettes.light[0],
		fontSize: 13,
	},
	labelLink: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		textDecorationLine: 'underline',
		color: '#98A2B2',
	},
	labelLinkMobile: {
		color: palettes.light[0],
		fontSize: 13,
	},
	label: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	labelMobile: {
		marginTop: 15,
	},
});

export default CustomLabel;
