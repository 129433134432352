import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	TouchableOpacity,
	View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'rn-css';
import { Button, Loader } from '../..';
import { socket } from '../../../../App';
import { useCreateMessage, useGetConversation } from '../../../api/chat';
import useGetConversationByParticipants from '../../../api/chat/useGetConversationByParticipants';
import { palettes } from '../../../config';
import { FOLLOW_ME, UNFOLLOW_ME } from '../../../graphql/follow/mutations';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setCurrentChat } from '../../../redux/chatSlice';
import { useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import { TStyle } from '../../../types/TStyle';
import { getLinkFromString, updateOneChat } from '../../../utils/chatUtil';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { manipulateString } from '../../../utils/manipulateString';
import { twoDp } from '../../../utils/twoDP';
import ChatBox from '../../chat/chat-box/ChatBox';
import { MessageData } from '../../chat/contacts/ChatContacts';
import ChatInput from '../../live-event/product-section/event-chat/ChatInput';
import ContactMessage from '../../live-event/product-section/event-chat/ContactMessage';
import UserMessage from '../../live-event/product-section/event-chat/UserMessage';
import CartList from '../desktop-navigation-bar/Cart-List/CartList';
import Flag from '../flag/Flag';
import { AddUserIcon, RemoveUserIcon } from '../icons';
import ModalComponent, { Props as ModalProps } from '../modal/ModalComponent';
import ProfileIcon from '../ProfileIcon';
import Ratings from '../ratings/Ratings';
import ActionLink from '../styled-components/ActionLink';
import ChatChild from '../templates/ChatChild';
import ProductView from '../templates/ProductView';
import { TableHeader } from './TableHeader';

const ContentContainer = styled.View``;

interface Props<T> {
	user: T | any;
	headers: TTableHeader[];
	type?: TTableTypes;

	messageButton?: boolean;
	profileButton?: boolean;
	followButton?: boolean;
	onPressFollowButton?: (id: number) => void;
	isFollowing?: boolean;
	index: number;
	loadingFollowUser?: boolean;
	hasRequestBeenSent?: boolean;
	hasUnfollowRequestBeenSent?: boolean;
	onClickHostFollowMeActionButton?: (
		id: string,
		hasRequestBeenSent: boolean,
		index?: number
		// isFollowingMe: boolean
	) => void;
	isFollowingMe: boolean;
	followMeUnfollowMeButtonPressedId?: string;
	loadingFollowMe?: boolean;
	eventsAttendedCount?: number;
}

export const TableContent = <T extends unknown>({
	user,
	headers,
	type = 'order',
	messageButton = false,
	profileButton = true,
	followButton = true,
	onPressFollowButton = () => {},
	index,
	isFollowing,
	loadingFollowUser = false,
	hasRequestBeenSent,
	hasUnfollowRequestBeenSent,
	onClickHostFollowMeActionButton,
	isFollowingMe,
	loadingFollowMe,
	eventsAttendedCount,
}: Props<T>) => {
	const [hovered, setHovered] = useState(false);
	const [modalDetails, setModalDetails] = useState<ModalProps>();
	let userId: undefined | string;
	let userPicture: undefined | string;
	let countryCode: undefined | string;
	let date: Date | undefined;
	const userData = Object.values(user);
	if (
		type !== 'vouchers' &&
		type !== 'order' &&
		type !== 'singleOrder' &&
		type !== 'profile'
	) {
		userPicture = userData.shift() as string;
	}
	if (type === 'member') {
		countryCode = userData.shift() as string;
	}
	if (type === 'singleOrder') {
		userData.splice(userData.length - 3, 3);
	}
	if (type === 'order') {
		userData.pop();
		date = new Date(Number(user?.purchasedOn));
	}
	if (type === 'profile') {
		userData.splice(userData.length - 4, 4);
	}

	if (!!profileButton && !!user?._id) {
		userId = userData.pop() as string;
	}
	const { t } = useTranslation();
	const navigation = useNavigation<TUseNavigation>();
	// const account = useAppSelector(state => state.auth.loggedInUserDetails?._id)
	const [hasRequestBeenSent_, setHasRequestBeenSent_] =
		useState(hasRequestBeenSent);
	const dispatch = useDispatch();
	const chat = useAppSelector(state => state.chat.chat);

	const [chatModalState, setAddChatModalState] = React.useState(false);
	// const [productModalState,setProductModalState]=useState(false)
	const changeChatModalState = () => {
		dispatch(setCurrentChat(user));
		setTimeout(() => {
			setAddChatModalState(!chatModalState);
		}, 500);
	};
	// const changeProductModalState=()=>{
	// 	setProductModalState(!productModalState);
	// }
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const { setAlert } = useSetAlert();
	const [unfollowWithoutRequest, { loading, data }] = useMutation(
		UNFOLLOW_ME,
		{
			onCompleted: data => {
				if (
					data.UnFollowMe.success &&
					data.UnFollowMe.statusCode === 200
				) {
					setAlert('Unfollow request sent!', 'normal');
				} else {
					setAlert('Unable to send unfollow request!', 'danger');
				}
			},
			onError: () => {
				setAlert('Unable to send unfollow request!', 'danger');
			},
		}
	);

	/**
	 * FUNCTIONALITY FOR CHAT START
	 */

	const [conversationId, setConversationId] = useState('');
	const [messages, setMessages] = useState<TODO[]>([]);
	const [message, setMessage] = useState('');
	const [ChatChild00, setChatChild] = useState<JSX.Element>();
	useEffect(() => {
		if (messages) {
			const x = messages;
			setChatChild(
				<ChatChild
					scrollViewRef={scrollViewRef}
					setMessage={setMessage}
					handleMessageSend={handleMessageSend}
					messages={x}
				/>
			);
		}
	}, [messages]);

	const { executeConversationMutation, data: conversationData } =
		useGetConversationByParticipants();

	useEffect(() => {
		executeConversationMutation({
			participants: [loggedInUserDetails?._id, user._id],
		});

		socket.on('sendMessage', message => {
			setMessages(prev => updateOneChat(prev, message.message));
		});
	}, []);

	useEffect(() => {
		const conversationId =
			conversationData?.getConversationByParticipants?.data?.id;
		if (conversationId) {
			setConversationId(conversationId);
		}
	}, [conversationData]);

	const { executeConversationQuery, data: conversationMessageData } =
		useGetConversation();

	useEffect(() => {
		executeConversationQuery({
			conversationId,
		});
	}, [conversationId]);

	useEffect(() => {
		const messageData =
			conversationMessageData?.getOneConversationByUserId?.data?.messages;
		if (messageData?.length) {
			setMessages(messageData);
		}
	}, [conversationMessageData]);

	const { executeCreateMessageMutation } = useCreateMessage();

	const handleMessageSend = ({
		message,
		messageType = 'TEXT',
	}: {
		message: string;
		messageType: string;
	}) => {
		socket.emit('sendMessage', {
			message: {
				message: message,
				recipients: user._id,
				sender: loggedInUserDetails?._id,
				messageType: messageType,
				recipientsReadReceipt: false,
				createdAt: new Date().getTime(),
			},
			contacts: {
				contactPerson: loggedInUserDetails?._id,
				conversationId: conversationId,
			},
			recipients: user._id,
		});

		setMessages(prev => [
			...prev,
			{
				message: message,
				recipients: user._id,
				messageType: messageType,
				sender: loggedInUserDetails?._id,
			},
		]);
		executeCreateMessageMutation({
			sender: loggedInUserDetails?._id as string,
			recipients: user._id,
			message,
			messageType: messageType,
			conversationId: conversationId,
		});
	};

	const scrollViewRef: any = useRef();

	/**
	 * FUNCTIONALITY FOR CHAT END
	 */

	useEffect(() => {
		// if(!isFollowingMe && hasRequestBeenSent){
		// 	setHasRequestBeenSent_(false)
		// }
		// else{
		setHasRequestBeenSent_(hasRequestBeenSent);
		// }
	}, [hasRequestBeenSent]);
	const FOLLOWER_STATS: TTableHeader[] = [
		{ headerLabel: t('Guests'), width: '33.33%' },
		{ headerLabel: t('Sellers'), width: '33.33%' },
		{ headerLabel: t('Hosts'), width: '33.33%' },
	];

	return (
		<>
			<ModalComponent
				type={modalDetails?.type}
				isNotScrollable={true}
				header={modalDetails?.header ?? '...'}
				openState={chatModalState}
				onClose={changeChatModalState}
				subtitle={modalDetails?.subtitle}
				contentMargin={1}
			>
				{ChatChild00 ?? <></>}
			</ModalComponent>

			<Pressable>
				{type === 'profile' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						{userData.map((data, index) => {
							return (
								<Text
									key={index}
									style={[
										styles.textStyle,
										styleUserData(headers, index),
									]}
								>
									{index === 6 && (
										<Pressable
											onPress={() =>
												navigation.navigate(
													'UserProfile',
													{
														userId: user?._id,
													}
												)
											}
										>
											<ProfileIcon
												style={styleProfileIcon()}
												imageSrc={user?.customerImage}
											/>
										</Pressable>
									)}
									{index === 7 && (
										<Text
											style={styles.hoverText}
											onPress={() =>
												navigation.navigate(
													'OrderDetails',
													{
														_id: user._id,
														orderDetails: user,
													}
												)
											}
										>
											{t('View')}
										</Text>
									)}
									{data}
								</Text>
							);
						})}
					</ContentContainer>
				)}
				{type === 'vouchers' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						{userData.map((data: any, index) => (
							<Text
								key={index}
								style={[
									styles.textStyle,
									styleUserData(headers, index),
								]}
							>
								{/* {index === 0 && (
									<ProfileIcon
										style={styleProfileIcon()}
										imageSrc={data.profileImageLink}
									/>
								)} */}

								{data}
							</Text>
						))}
						{/* <View style={styles.actionContainer}>
							{followButton && (
								<Button
									title={
										isFollowing
											? t('Unfollow')
											: t('Follow')
									}
									onPress={() => {
										onPressFollowButton &&
											onPressFollowButton(index);
									}}
									loading={loadingFollowUser}
								/>
							)}
							{messageButton && <Button title={t('Message')} />}
							{profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: userId && userId,
										})
									}
								/>
							)}
						</View> */}
					</ContentContainer>
				)}
				{type === 'order' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 0, hovered),
							]}
						>
							{user.orderId}
						</Text>
						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 1, hovered),
							]}
						>
							{date
								? date.getDate() +
								  '/' +
								  (date.getMonth() + 1) +
								  '/' +
								  date.getFullYear()
								: '...'}
						</Text>
						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 2, hovered),
							]}
						>
							{user.brandName}
						</Text>

						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 3, hovered),
							]}
						>
							{getSymbolFromCurrency('eur')}
							{twoDp(user.purchasedTotalAmount)}
						</Text>
						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 4, hovered),
							]}
						>
							{'x' + user?.rate}
						</Text>
						<Text
							numberOfLines={1}
							style={[
								styles.textStyle,
								styleUserData(headers, 5, hovered),
							]}
						>
							{user?.status}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 6, hovered),
							]}
						>
							<ActionLink
								onPress={() =>
									navigation.navigate('OrderDetails', {
										_id: user._id,
										orderDetails: user,
									})
								}
							>
								{t('View')}
							</ActionLink>
						</Text>

						{/* <View style={styles.actionContainer}>
							{followButton && (
								<Button
									title={
										isFollowing
											? t('Unfollow')
											: t('Follow')
									}
									onPress={() => {
										onPressFollowButton &&
											onPressFollowButton(index);
									}}
									loading={loadingFollowUser}
								/>
							)}
							{messageButton && <Button title={t('Message')} />} */}
						{/* {profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: userId && userId,
										})
									}
								/>
							)} */}
						{/* </View> */}
					</ContentContainer>
				)}

				{type === 'member' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						{userData.map((data: any, index: number) => (
							<>
								<Text
									key={index}
									style={[
										styles.textStyle,
										styleUserData(headers, index),
									]}
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: user?._id,
										})
									}
								>
									{index === 0 && (
										<ProfileIcon
											style={styleProfileIcon()}
											imageSrc={userPicture}
										/>
									)}
									{data}
								</Text>
							</>
						))}

						<View style={styles.actionContainer}>
							{followButton && (
								<TouchableOpacity
									onPress={() => {
										onPressFollowButton &&
											userId &&
											onPressFollowButton(
												parseInt(userId)
											);
									}}
								>
									{!loadingFollowUser && isFollowingMe ? (
										<AddUserIcon
											style={styleAddUserIcon()}
										/>
									) : (
										!loadingFollowUser && (
											<RemoveUserIcon
												style={styleAddUserIcon()}
											/>
										)
									)}
									{loading && <Loader />}
								</TouchableOpacity>
							)}
							{messageButton &&
								user.typeOfAccount !== 'CONSUMER' && (
									<Button
										onPress={() => {
											changeChatModalState();
											setModalDetails({
												type: 'sidebar',
												isNotScrollable: true,
												header: (
													<Text
														style={[
															styles.textStyle,
														]}
														onPress={() =>
															navigation.navigate(
																'UserProfile',
																{
																	userId: user?._id,
																}
															)
														}
													>
														<ProfileIcon
															style={styleProfileIcon()}
															imageSrc={
																userPicture
															}
														/>
														{user.username}
													</Text>
												),
												openState: chatModalState,
												onClose: changeChatModalState,
												children: <></>,
												subtitle: (
													<>
														<Flag
															countryCode={
																countryCode
															}
														/>
														{user.city}
														{', '}
														{user.countryLabel}
													</>
												),
												contentMargin: 1,
											});
										}}
										title={t('Message')}
									/>
								)}

							{profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: user?._id,
										})
									}
								/>
							)}
						</View>
					</ContentContainer>
				)}

				{type === 'host' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 0),
							]}
							onPress={() =>
								navigation.navigate('UserProfile', {
									userId: user?._id,
								})
							}
						>
							<ProfileIcon
								style={styleProfileIcon()}
								imageSrc={
									user?.personalInformation?.profileImageLink
								}
							/>
							{user?.username}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 1),
							]}
						>
							{user?.rating ? (
								<Ratings ratings={user?.rating} />
							) : (
								'NR'
							)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 2),
							]}
						>
							{t(manipulateString.capitalize(user?.gender)) ||
								'N/A'}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 3),
							]}
						>
							{user?.followersCount}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 4),
							]}
						>
							{manipulateString.capitalize(
								user?.personalInformation?.address?.city
							)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 5),
							]}
						>
							{manipulateString.capitalize(
								user?.personalInformation?.address?.countryLabel
							)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 6),
							]}
						>
							{user?.eventsCount}
						</Text>
						<View style={styles.actionContainer}>
							{followButton && (
								<Button
									title={
										isFollowing
											? t('Unfollow')
											: t('Follow')
									}
									onPress={() => {
										onPressFollowButton &&
											onPressFollowButton(index);
									}}
									loading={loadingFollowUser}
									width={60}
								/>
							)}
							{messageButton && <Button title={t('Message')} />}
							{profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: user?._id,
										})
									}
									width={50}
								/>
							)}
						</View>
					</ContentContainer>
				)}
				{type === 'consumer' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 0),
							]}
							onPress={() =>
								navigation.navigate('UserProfile', {
									userId: user?._id,
								})
							}
						>
							<ProfileIcon
								style={styleProfileIcon()}
								imageSrc={
									user?.personalInformation?.profileImageLink
								}
							/>
							{user?.username}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 1),
							]}
						>
							{t(manipulateString.capitalize(user?.gender)) ||
								'N/A'}
						</Text>

						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 2),
							]}
						>
							{manipulateString.capitalize(
								user?.personalInformation?.address?.city
							)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 3),
							]}
						>
							{manipulateString.capitalize(
								user?.personalInformation?.address?.countryLabel
							)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 4),
							]}
						>
							{user?.eventsCount}
						</Text>
						<View style={styles.actionContainer}>
							{followButton &&
								(hasRequestBeenSent_ ||
								hasUnfollowRequestBeenSent ? (
									<Button
										title={t('Request Sent')}
										variant="disabled"
									/>
								) : isFollowingMe ? (
									<Button
										onPress={() => {
											onClickHostFollowMeActionButton &&
												onClickHostFollowMeActionButton(
													user?._id,
													true,
													index
												);
										}}
										title={t('Unfollow Me')}
										loading={loadingFollowMe}
										width={50}
									/>
								) : (
									!isFollowingMe && (
										<Button
											onPress={() => {
												onClickHostFollowMeActionButton &&
													onClickHostFollowMeActionButton(
														user?._id,
														false,
														index
													);
											}}
											title={t('Follow Me')}
											loading={loadingFollowMe}
											width={50}
										/>
									)
								))}
							{/* {followButton &&
								(hasRequestBeenSent_ && isFollowingMe ? (
									<Button
										onPress={() => {
											onClickHostFollowMeActionButton &&
												onClickHostFollowMeActionButton(
													user?._id,
													hasRequestBeenSent_,
													index
												);
										}}
										title={t('Unfollow Me')}
										loading={loadingFollowMe}
									/>
								) : !hasRequestBeenSent_ && !isFollowingMe ? (
									<Button
										onPress={() => {
											onClickHostFollowMeActionButton &&
												onClickHostFollowMeActionButton(
													user?._id,
													hasRequestBeenSent_,
													index
												);
										}}
										title={t('Follow Me')}
										loading={loadingFollowMe}
									/>
								) : (hasRequestBeenSent_ ||
									hasUnfollowRequestBeenSent) &&
									(!isFollowingMe || isFollowingMe) ? (
									<Button title={t('Request Sent')} />
								) : (
									isFollowingMe &&
									!hasRequestBeenSent_ && (
										// <Button
										// 	title={t('Request Sent')} /> :
										// isFollowingMe &&
										<Button
											title={
												loading
													? t('Sending request')
													: data
														? t('Request Sent')
														: t('Unfollow me')
											}
											onPress={() =>
												unfollowWithoutRequest({
													variables: {
														args: {
															recipientId:
																user?._id,
														},
													},
												})
											}
											loading={loadingFollowMe}
										/>
									)
								))} */}
							{messageButton && <Button title={t('Message')} />}
							{profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('UserProfile', {
											userId: user?._id,
										})
									}
								/>
							)}
						</View>
					</ContentContainer>
				)}
				{type === 'singleOrder' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						{userData?.map((data: any, index) => (
							<Text
								key={index}
								numberOfLines={2}
								style={[
									styles.textStyle,
									styleUserData(headers, index, hovered),
								]}
							>
								{index === 7 &&
									(user.type === 'PRODUCT' ? (
										<ActionLink
											onPress={() => {
												changeChatModalState();
												setChatChild(
													<ProductView
														variant={user?.variants}
													/>
												);
												setModalDetails({
													type: 'sidebar',
													isNotScrollable: true,
													header: `${t(
														'Product View'
													)}`,
													openState: chatModalState,
													onClose:
														changeChatModalState,
													children: <></>,
													contentMargin: 1,
												});
											}}
										>
											{t('View')}
										</ActionLink>
									) : (
										<Text>N/A</Text>
									))}
								{data}
							</Text>
						))}
					</ContentContainer>
				)}
				{type === 'brand' && (
					<ContentContainer
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						style={styleContentContainer(hovered)}
					>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 0),
							]}
							onPress={() =>
								navigation.navigate('UserProfile', {
									userId: user?._id,
								})
							}
						>
							<ProfileIcon
								style={styleProfileIcon()}
								imageSrc={user?.profileImage}
							/>
							{user?.name}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 1),
							]}
						>
							<Ratings ratings={user?.rating || 0} />
						</Text>

						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 2),
							]}
						>
							{user?.brandCategory ?? 'N/A'}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 3),
							]}
						>
							<ContentContainer style={styleFollowerContainer()}>
								<View
									style={[
										styleFollowersData(FOLLOWER_STATS, 0),
									]}
								>
									<Text
										style={[
											styles.name,
											styleUserData(FOLLOWER_STATS, 0),
										]}
									>
										{user?.guest}
									</Text>
									<Text
										style={[styles.fType]}
										numberOfLines={2}
									>
										{t('Guests')}
									</Text>
								</View>
								<View
									style={[
										styleFollowersData(FOLLOWER_STATS, 1),
									]}
								>
									<Text style={[styles.name]}>
										{user?.seller}
									</Text>
									<Text
										style={[styles.fType]}
										numberOfLines={2}
									>
										{t('Sellers')}
									</Text>
								</View>
								<View
									style={[
										styleFollowersData(FOLLOWER_STATS, 2),
									]}
								>
									<Text style={[styles.name]}>
										{user?.host}
									</Text>
									<Text
										style={[styles.fType]}
										numberOfLines={2}
									>
										{t('Hosts')}
									</Text>
								</View>
							</ContentContainer>
						</Text>

						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 4),
							]}
						>
							{getSymbolFromCurrency('eur')}
							{twoDp(user?.averagePrice)}
						</Text>
						<Text
							style={[
								styles.textStyle,
								styleUserData(headers, 4),
							]}
						>
							{user?.address?.countryLabel}
						</Text>
						<View style={styles.actionContainer}>
							{followButton && <Button title={t('Follow Me')} />}
							{messageButton && <Button title={t('Message')} />}
							{profileButton && (
								<Button
									title={t('View Profile')}
									variant="grey"
									onPress={() =>
										navigation.navigate('BrandDetails', {
											_id: user._id,
										})
									}
								/>
							)}
						</View>
					</ContentContainer>
				)}
			</Pressable>
		</>
	);
};

const styles = StyleSheet.create({
	contentContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		borderWidth: 1,
		borderColor: 'transparent',
	},
	contentContainerHovered: {
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
	},
	firstCell: {
		fontWeight: '600',
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex',
		textTransform: 'none',
	},
	name: {
		fontWeight: '700',
		color: palettes.dark[0],
	},
	fType: {
		color: palettes.grey[0],
	},
	actionContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	actionButtonMarginRight: {
		marginRight: 16,
	},
	textStyle: {
		paddingRight: 5,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',

		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.purple[0],
		cursor: 'pointer',
	},
});

const styleUserData = (
	headers: TTableHeader[],
	index: number,
	hovered?: boolean
) => {
	return [
		{
			width: headers[index]?.width ?? 120,
			// textTransform: 'capitalize',
		},
		index === 0 && styles.firstCell,
	];
};
const styleFollowersData = (headers: TTableHeader[], index: number) => {
	return [{ width: headers[index]?.width }];
};
function styleAddUserIcon() {
	return {
		width: getResponsiveStyle(14, 'dimensions'),
		height: getResponsiveStyle(16, 'dimensions'),
		marginRight: getResponsiveStyle(5),
	};
}
const styleContentContainer = (hovered: boolean) => {
	return [
		styles.contentContainer,
		{
			paddingVertical: getResponsiveStyle(16),
			paddingLeft: getResponsiveStyle(16),
			paddingRight: getResponsiveStyle(20),
		} as TStyle,
		hovered && styles.contentContainerHovered,
	];
};
const styleFollowerContainer = () => {
	return [
		styles.contentContainer,
		{
			paddingVertical: getResponsiveStyle(16),
			paddingLeft: 0,
			paddingRight: getResponsiveStyle(20),
		} as TStyle,
	];
};

const styleProfileIcon = () => {
	return {
		width: getResponsiveStyle(32, 'dimensions'),
		height: getResponsiveStyle(32, 'dimensions'),
		marginRight: getResponsiveStyle(15),
	};
};
