import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import {
	CREATE_ADDRESS_BOOK,
	EDIT_ADDRESS_BOOK,
} from '../../../graphql/profile/mutations';
import { GET_ALL_ADDRESS_BOOKS } from '../../../graphql/profile/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { i18n } from '../../../i18n';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getCountryValueFromLabel } from '../../../utils/getCountryValueFromLabel';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import Loader from '../../common/loader/Loader';
import ModalComponent from '../../common/modal/ModalComponent';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import AddAddressForm from './AddAddressForm';
import AddressComponent from './AddressComponent';
import { useTranslation } from 'react-i18next';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch } from '../../../redux/store';
import { setDefaultAddress } from '../../../redux/userSlice';
import { setAddressBookLoading } from '../../../redux/loaderSlice';

type Props = {
	onCheckout?: boolean;
	setName?: (val: string) => void;
	setAddress?: (val: string) => void;
	setCity?: (val: string) => void;
	setZipCode?: (val: string) => void;
	setCountry?: (val: string) => void;
};
const AddressSection = ({
	onCheckout,
	setAddress,
	setCity,
	setCountry,
	setName,
	setZipCode,
}: Props) => {
	const { setAlert } = useSetAlert();
	const [addAddressModalState, setAddAddressModalState] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [addressBookToEditDetails, setAddressBookToEditDetails] =
		useState<TAddressBook>();
	const { t } = useTranslation();

	const [addressBooks, setAddressBooks] = useState<TAddressBook[]>([]);

	const changeAddressModalState = () => {
		setAddAddressModalState(!addAddressModalState);
	};

	const { loading: loadingGetAllAddressBooks } =
		useQuery<TGetAllAddressBooksResponse>(GET_ALL_ADDRESS_BOOKS, {
			onCompleted: data => {
				dispatch(setAddressBookLoading(false));
				if (
					data.getAllAddressBooks.data &&
					data.getAllAddressBooks.success
				) {
					setAddressBooks(data.getAllAddressBooks.data.data);
				}
			},
		});

	const handleAddressBookCreationError = () => {
		setAlert(t('Error occurred while creating address book!'), 'normal');
	};

	const refetchQueries = [
		{
			query: GET_ALL_ADDRESS_BOOKS,
		},
	];

	const [createAddressBook, { loading: loadingCreateAddressBook }] =
		useMutation<TCreateAddressBookResponse, TCreateAddressBookInputs>(
			CREATE_ADDRESS_BOOK,
			{
				awaitRefetchQueries: true,
				refetchQueries,
				onCompleted: data => {
					setAddAddressModalState(false);
					if (
						data.createAddressBook.data &&
						data.createAddressBook.success
					) {
						setAlert(
							t('Address book created successfully!'),
							'normal'
						);
					} else {
						handleAddressBookCreationError();
					}
				},
				onError: () => {
					handleAddressBookCreationError();
					setAddAddressModalState(false);
				},
			}
		);

	const dispatch = useAppDispatch();
	// useEffect(()=> {
	// 	dispatch(setAddressBookLoading(loadingGetAllAddressBooks))
	// }, [loadingGetAllAddressBooks])

	if (loadingGetAllAddressBooks) {
		dispatch(setAddressBookLoading(true));
	}
	const resetEditAddressBookValues = () => {
		setAddAddressModalState(false);
		setIsEdit(false);
		setAddressBookToEditDetails(undefined);
	};

	const [editAddressBook, { loading: loadingEditAddressBook }] = useMutation<
		TEditAddressBookResponse,
		TEditAddressBookInputs
	>(EDIT_ADDRESS_BOOK, {
		awaitRefetchQueries: true,
		refetchQueries,
		onCompleted: data => {
			resetEditAddressBookValues();

			if (data.editAddressBook.data && data.editAddressBook.success) {
				setAlert(t('Address book edited successfully!'), 'normal');
			} else {
				setAlert(t('Unable to edit address book!'), 'normal');
			}
		},
		onError: () => {
			resetEditAddressBookValues();
			setAlert(t('Unable to edit address book!'), 'normal');
		},
	});

	const onPressEditDetails = (values: Omit<TAddressBook, 'createdBy'>) => {
		setIsEdit(true);
		setAddressBookToEditDetails({
			...values,
			countery: getCountryValueFromLabel(values.countery),
		});
		setAddAddressModalState(true);
	};

	const handlePress = (values: Omit<TAddressBook, 'createdBy'>) => {
		setIsEdit(true);
		setAddressBookToEditDetails({
			...values,
			countery: getCountryValueFromLabel(values.countery),
		});
	};

	const onSubmitCreateUpdateAddressBook = (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		if (!values.countery) return;

		const args = {
			address: values.address,
			city: values.city,
			countery: values.countery,
			name: values.name,
			zipCode: values.zipCode,
			phoneNumber: values.phoneNumber,
		};

		if (isEdit && !!addressBookToEditDetails) {
			editAddressBook({
				variables: {
					_id: addressBookToEditDetails?._id,
					args,
				},
			});
		} else {
			createAddressBook({
				variables: {
					args,
				},
			});
		}
	};

	const loadingState = getSingleLoadingState(
		loadingEditAddressBook,
		loadingCreateAddressBook
	);

	const primary = usePrimaryColors();

	const [defaultAddressId, setDefaultAddressId] = useState<
		string | undefined
	>('');

	useEffect(() => {
		const defaultAddress_ = addressBooks?.find(
			(address: TAddressBook) => address.default === true
		);
		setDefaultAddressId(defaultAddress_?._id);
		dispatch(setDefaultAddress(defaultAddress_ as TAddressBook));
	}, [addressBooks]);

	const [defaultLoading, setDefaultLoading] = useState<boolean>(false);

	return (
		<View style={styles.container}>
			{addAddressModalState && (
				<ModalComponent
					type="sidebar"
					header={`${t('Add Address')}`}
					openState={addAddressModalState}
					onClose={changeAddressModalState}
				>
					<AddAddressForm
						onSubmit={onSubmitCreateUpdateAddressBook}
						loading={loadingState}
						isEdit={isEdit}
						addressBookToEditDetails={addressBookToEditDetails}
						setName={setName}
						setAddress={setAddress}
						setCity={setCity}
						setZipCode={setZipCode}
						setCountry={setCountry}
					/>
				</ModalComponent>
			)}
			<View style={styles.headerContainer}>
				<Text style={styles.headerText}>{t('Shipping Address')}</Text>
				{/* {true && (
					<Text
						style={[
							styles.addText,
							{
								color: primary[0],
								textDecorationLine: 'underline',
							},
						]}
						onPress={changeAddressModalState}
					>
						+ {t('Add New Address')}
					</Text>
				)} */}
			</View>
			<View>
				<Text style={styles.subHeading}>
					{t(
						'Free delivery for purchases over 59€ in France. Safe and secure payment - 3D secure.'
					)}
				</Text>
			</View>
			{loadingGetAllAddressBooks ? (
				<Loader />
			) : !!addressBooks && addressBooks.length > 0 ? (
				<>
					<Text style={styles.selectText}>
						{t('Select one of the following address')}
					</Text>
					<View style={styles.contentContainer}>
						{!defaultLoading ? (
							addressBooks.map((addressBook, index) =>
								addressBook.default ? (
									<AddressComponent
										defaultAddress
										_id={addressBook._id}
										key={addressBook._id}
										index={index}
										zipCode={addressBook.zipCode}
										name={addressBook.name}
										address={addressBook.address}
										city={addressBook.city}
										phoneNumber={addressBook.phoneNumber}
										onPressEditDetails={onPressEditDetails}
										handlePress={handlePress}
										countery={getCountryLabelFromValue(
											addressBook.countery
										)}
										defaultAddressId={defaultAddressId}
										setDefaultLoading={setDefaultLoading}
										// addresses={addressBooks}
									/>
								) : (
									<AddressComponent
										_id={addressBook._id}
										key={addressBook._id}
										index={index}
										zipCode={addressBook.zipCode}
										name={addressBook.name}
										address={addressBook.address}
										phoneNumber={addressBook.phoneNumber}
										city={addressBook.city}
										handlePress={handlePress}
										onPressEditDetails={onPressEditDetails}
										countery={getCountryLabelFromValue(
											addressBook.countery
										)}
										// addresses={addressBooks}
										defaultAddressId={defaultAddressId}
										setDefaultLoading={setDefaultLoading}
									/>
								)
							)
						) : (
							<View
								style={{
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'row',
									flex: 1,
								}}
							>
								<Loader />
							</View>
						)}
						<AddAddressForm
							onSubmit={() => {}}
							loading={loadingState}
							isEdit={isEdit}
							addressBookToEditDetails={addressBookToEditDetails}
							onCheckout={onCheckout}
						/>
					</View>
				</>
			) : onCheckout ? (
				<AddAddressForm
					onSubmit={() => {}}
					loading={loadingState}
					isEdit={isEdit}
					addressBookToEditDetails={addressBookToEditDetails}
					onCheckout={onCheckout}
				/>
			) : (
				<NotAvailableMessage message={t('No address books saved!')} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	indicatorContainer: {
		height: '500px',
	},
	addText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		cursor: 'pointer',
	},
	container: {
		width: '100%',
		// marginTop: 48,
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
		marginBottom: getResponsiveStyle(14, 'spacing'),
	},
	subHeading: {
		color: palettes.grey[0],
		fontSize: getResponsiveStyle(20, 'font'),
		marginBottom: getResponsiveStyle(14, 'spacing'),
	},
	selectText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		gap: getResponsiveStyle(24),
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		marginTop: getResponsiveStyle(24),
	},
});

export default AddressSection;
