import { useLazyQuery, useQuery } from '@apollo/client';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { Loader } from '../components';
import CartList from '../components/common/desktop-navigation-bar/Cart-List/CartList';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import AddressSection from '../components/profile/Profile-Address-Book/AddressSection';
import { TCard } from '../components/profile/Profile-Wallet/CardSection';
import { palettes } from '../config';
import { GET_APP_CONFIG } from '../graphql/config/queries';
import { LIST_CARDS } from '../graphql/stripe/queries';
import { GET_ALL_VOUCHERs } from '../graphql/vouchers/queries';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import {
	setDefaultCard,
	setloadingCards,
	setUserCards,
} from '../redux/getCardsSlice';
import { setVoucherLoading } from '../redux/loaderSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setWasCardUsed } from '../redux/userSlice';
import {
	SelectedVoucher,
	setSelectedCardFn,
	setSelectedVoucher,
	setVouchers,
} from '../redux/walletSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { setCurrentScreen } from '../redux/uiSlice';

const CheckoutAddressScreen = () => {
	const dispatch = useAppDispatch();
	const { shouldHideScreen } = useHandleUnmount();

	useEffect(() => {
		dispatch(setCurrentScreen('/checkout-address'));
	}, []);
	const cards = useAppSelector(state => state.userCards.UserCards);
	useEffect(() => {
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].default) {
				dispatch(setDefaultCard(cards[i]));
				break;
			}
		}
	}, [cards]);

	useEffect(() => {
		dispatch(setWasCardUsed(false));
		dispatch(setSelectedVoucher({} as SelectedVoucher));
		dispatch(setSelectedCardFn({} as TCard));
	}, []);

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const stripeId_ = useAppSelector(state => state.getStripeId.stripeID);

	const [deliveryFee, setDeliveryFee] = useState(0);
	const [maxOrderForDeliveryFee, setMaxOrderForDeliveryFree] = useState(0);

	const { t } = useTranslation();
	const { loading: CardsLoading, data: cardsListed } = useQuery(LIST_CARDS, {
		variables: {
			args: {
				id: stripeId_,
			},
		},
		onCompleted: res => {
			dispatch(setUserCards(res.listCards.data.data));
		},
	});

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	let Total = 0;
	usersCart.map(product => {
		Total +=
			product.type === 'Product'
				? product?.price * product.quantity
				: product.starterkitId.price * product.quantity;
	});
	const [amountToBePaid, setAmountToBePaid] = useState<number>(Total);

	useEffect(() => {
		setAmountToBePaid(Total);
	}, [Total]);
	const { data: vouchersData, loading: gettingVoucher } = useQuery(
		GET_ALL_VOUCHERs,
		{
			onCompleted: () => {
				dispatch(setVouchers(vouchersData?.getAllVouchers?.data?.data));
				dispatch(setVoucherLoading(false));
			},
		}
	);

	let brandsInCart: any = {};

	let brandInCartName: string[] = [];
	usersCart.map(product => {
		if (product.type === 'Product') {
			if (
				Object.keys(brandsInCart).includes(
					product.productId?.associatedProduct?.brand?._id
				)
			) {
				brandsInCart[
					product.productId?.associatedProduct?.brand
						?._id as keyof typeof brandsInCart
				] += 1;
				// brandCounts += 1
			} else {
				brandsInCart[
					product.productId?.associatedProduct?.brand
						?._id as keyof typeof brandsInCart
				] = 1;
				brandInCartName.push(
					product.productId?.associatedProduct?.brand?.name
				);
			}
		} else {
			if (
				Object.keys(brandsInCart).includes(
					product.starterkitId.brand._id
				)
			) {
				brandsInCart[
					product.starterkitId.brand._id as keyof typeof brandsInCart
				] += 1;
				// brandCounts += 1
			} else {
				brandsInCart[
					product.starterkitId.brand._id as keyof typeof brandsInCart
				] = 1;
				brandInCartName.push(product.starterkitId.brand.name);
			}
		}
	});

	// useEffect(() => {
	// 	if (
	// 		(chargingData?.chargeCustomer?.success === true &&
	// 			chargingData?.hargeCustomer?.statusCode === 200) ||
	// 		voucherPaymentSuccessful ||
	// 		(chargingWithoutCardData?.chargeCustomerWithoutSavingCard
	// 			?.success === true &&
	// 			chargingWithoutCardData?.chargeCustomerWithoutSavingCard
	// 				?.statusCode === 200)
	// 	) {
	// 		setAlert(t('Payment successful'), 'normal');
	// 		let vouchersUsedToPay: TODO[] = [];
	// 		Object.keys(payingVouchers).map(id => {
	// 			const voucher_ = vouchers?.find(vouch => vouch._id === id);
	// 			vouchersUsedToPay.push(voucher_);
	// 		});
	// 		dispatch(setPaymentVouchers(vouchersUsedToPay));
	// 		navigation.navigate('PurchaseComplete');
	// 	}
	// }, [chargingData, voucherPaymentSuccessful, chargingWithoutCardData]);

	useEffect(() => {
		if (CardsLoading) {
			dispatch(setloadingCards(true));
		}

		if (cardsListed) {
			dispatch(setloadingCards(false));
		}
	}, [cardsListed, CardsLoading]);

	const [getAppConfig_] = useLazyQuery(GET_APP_CONFIG);

	useEffect(() => {
		console.log({ Total });

		getAppConfig_({
			variables: {
				key: 'MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT',
			},
			onCompleted: data => {
				setDeliveryFee(
					Total < +data?.getAppConfig?.data?.value?.value2
						? +data?.getAppConfig?.data?.value?.value1
						: 0
				);
				setMaxOrderForDeliveryFree(
					data?.getAppConfig?.data?.value?.value2
				);
			},
		});
	}, []);

	useEffect(() => {
		if (Total < +maxOrderForDeliveryFee) {
			setAmountToBePaid(Total + deliveryFee);
		}
	}, [Total, deliveryFee, maxOrderForDeliveryFee]);

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			{CardsLoading || gettingVoucher ? (
				<Loader />
			) : (
				<>
					<View style={styles.container}>
						<View
							style={
								!isLessThanDesktopBase
									? styles.container_1
									: { width: '100%' }
							}
						>
							<AddressSection
								onCheckout={true}
								// setName={setAddressBookName}
								// setAddress={setAddressBookAddress}
								// setCity={setAddressBookCity}
								// setZipCode={setAddressBookZipCode}
								// setCountry={setAddressBookCountery}
							/>
							{/* {userAccountType === 'HOST' && <VoucherSection />} */}
							{/* <CardSection onCheckout="yes" /> */}
						</View>
						{!isLessThanDesktopBase && (
							<View style={styles.container_2}>
								{usersCart.length > 0 && (
									<>
										<Text style={styles.headerText}>
											{/* {t('All Products')}(
											{usersCart.length < 10
												? `0${usersCart.length}`
												: usersCart.length}
											) */}
											{t('My cart')}
										</Text>
										<CartList onCheckout />
									</>
								)}

								<View
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
									}}
								>
									<View style={styles.breakDown}>
										<Text style={styles.subTotalText}>
											{t('Delivery Fee')}:
										</Text>
										<Text
											style={[
												styles.subtotal,
												{
													fontSize:
														getResponsiveStyle(11),
												},
											]}
										>
											{getSymbolFromCurrency('eur')}
											{Total > maxOrderForDeliveryFee
												? 0
												: deliveryFee}
										</Text>
									</View>

									<View style={styles.total}>
										<Text style={styles.subTotalText}>
											{isLessThanDesktopBase
												? ''
												: t('Total')}
										</Text>
										<Text style={styles.subtotal}>
											{isLessThanDesktopBase
												? ''
												: `${getSymbolFromCurrency(
														'eur'
												  )}${amountToBePaid}`}
										</Text>
									</View>
								</View>
							</View>
						)}
					</View>

					<View
						style={
							!isLessThanDesktopBase
								? {
										display: 'flex',
										flexDirection: 'row',
										marginTop: 40,
										marginBottom: 20,
								  }
								: {
										display: 'flex',
										flexDirection: 'row',
										marginTop: 20,
										marginBottom: 100,
										justifyContent: 'center',
								  }
						}
					></View>
				</>
			)}
		</DesktopTemplate>
	);
};

export default CheckoutAddressScreen;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	total: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginTop: '15px',
		paddingTop: '15px',
		justifyContent: 'space-between',
		// marginLeft: '5%',
		borderTopColor: palettes.grey[5],
		height: 1,
		borderTopWidth: 1,
	},
	container_1: {
		width: '57%',
	},
	container_2: {
		borderLeftWidth: 1,
		borderLeftColor: 'whitesmoke',
		// marginRight: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(17),
		width: '40%',
	},
	subtotal: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[2],
		fontWeight: 'bold',
	},
	subTotalText: {
		fontSize: getResponsiveStyle(11),
		lineHeight: getResponsiveStyle(18),
		color: palettes.grey[1],
	},
	breakDown: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(14, 'font'),
	},
	confirm: {
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		borderRadius: 5,
		marginTop: 10,
	},
});
