import { useLazyQuery, useMutation } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	FilterCategories,
	Loader,
	LoadMore,
	ProfileCards,
	ProfileTableControl,
	Tabs,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GET_ALL_USERS, GET_USER_TABLE_STATS } from '../graphql/auth/queries';
import { FOLLOW_ME, UNFOLLOW_ME } from '../graphql/follow/mutations';
import {
	IS_FOLLOWING_ME_REQUEST_SENT,
	IS_UNFOLLOWING_ME_REQUEST_SENT,
} from '../graphql/follow/queries';
import {
	GET_ALL_CONSUMERS,
	GET_ALL_HOSTS,
	GET_ALL_SELLERS,
	IS_FOLLOWING,
	IS_FOLLOWING_ME,
} from '../graphql/network/queries';
import { GET_USER_CATEGORY, GET_USER_FOLLOWERS } from '../graphql/user/queries';
import { usePagination } from '../hooks/useCurrentForPagination';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useSetAlert } from '../hooks/useSetAlerts';
import { resetFilters } from '../redux/filterSlice';
import { resetSort } from '../redux/sortSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
	setFollowMeIds,
	setIsFollowingMeResult,
	setNetworkFollowedIds,
} from '../redux/userSlice';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { sortCheck, sortValue } from '../utils/handleSort';
import { setCurrentScreen } from '../redux/uiSlice';
const NetworkScreen = () => {
	const { setAlert } = useSetAlert();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { shouldHideScreen } = useHandleUnmount();
	const { DROPDOWN_CATEGORIES, PILL_INIT_CATEGORIES, PILL_CATEGORIES } =
		useAppSelector(state => state.tags);

	const [allHosts, setAllHosts] = useState<any[]>([]);
	const [allSellers, setAllSellers] = useState<any[]>([]);
	const [allConsumers, setAllConsumers] = useState<any[]>([]);
	const [selected, setSelected] = useState('');
	const [allValues, setAllValues] = useState<any[]>([]);
	const [allIds, setAllIds] = useState<string[]>([]);
	const [filterIds, setFilterIds] = useState<string[]>([]);
	const [allStats, setAllStats] = useState<any[]>([]);
	const [allHostCurrent, increaseAllHostCurrent, setCurrentHost] =
		usePagination();
	const [allSellerCurrent, increaseAllSellerCurrent, setCurrentSeller] =
		usePagination();
	const [allConsumerCurrent, increaseAllConsumerCurrent, setCurrentConsumer] =
		usePagination();
	const [selectedCategory, setSelectedCategory] = useState('all');
	const [hostFilteredData, setHostFilterData] = useState<any[]>([]);

	const SELLER_TABS = [
		{ label: t('Hosts'), value: 'Hosts' },
		{ label: t('Guests'), value: 'Guests' },
	];

	const HOSTS_TABS = [
		{ label: t('Sellers'), value: 'Sellers' },
		{ label: t('Guests'), value: 'Guests' },
	];

	const BRANDS_TABS = [{ label: t('Guests'), value: 'Guests' }];

	const GUEST_TABS = [
		{ label: t('Hosts'), value: 'Hosts' },
		{ label: t('Sellers'), value: 'Sellers' },
	];

	const VISITORS_TABS = [
		{ label: t('Hosts'), value: 'Hosts' },
		{ label: t('Sellers'), value: 'Sellers' },
	];
	const generalNetworkSort = [
		{
			label: `${t('Username')}: A-Z`,
			local: false,
			value: { username: 1 },
		},
		{
			label: `${t('Username')}: Z-A`,
			local: false,
			value: { username: -1 },
		},

		{
			label: `${t('City')}: A-Z`,
			local: false,
			value: { personalInformation: { address: { city: 1 } } },
		},
		{
			label: `${t('City')}: Z-A`,
			local: false,
			value: { personalInformation: { address: { city: -1 } } },
		},
		{
			label: `${t('Country')}: A-Z`,
			local: false,
			value: { personalInformation: { address: { countryLabel: 1 } } },
		},
		{
			label: `${t('Country')}: Z-A`,
			local: false,
			value: { personalInformation: { address: { countryLabel: -1 } } },
		},
		{
			label: `${t('Events Attended')}: ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'eventsCount', value: -1 },
		},
		{
			label: `${t('Events Attended')}: ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'eventsCount', value: 1 },
		},
	];
	const networkHostSort = [
		{
			label: `${t('Followers')}: ${t('Highest to Lowest')}`,
			local: true,
			value: { label: 'followersCount', value: -1 },
		},
		{
			label: `${t('Followers')}: ${t('Lowest to Highest')}`,
			local: true,
			value: { label: 'followersCount', value: 1 },
		},
	];
	const networkFilters = [
		{ label: t('City'), value: 'useraddcity' },
		{ label: t('Zip Code'), value: 'useraddzipCode' },
		{ label: t('Country'), value: 'useraddcountryCode' },
		{ label: t('Gender'), value: 'gender' },
		{ label: t('Interest tags'), value: 'userinterestTags' },
		{ label: t('Rating'), value: 'rating' },
	];

	const getTabs = (userType: TUserType) => {
		switch (userType) {
			case 'host':
				return HOSTS_TABS;
			case 'seller':
				return SELLER_TABS;
			case 'brand':
				return BRANDS_TABS;
			case 'consumer':
				return GUEST_TABS;
			case 'visitor':
				return VISITORS_TABS;
			default:
				return [];
		}
	};
	const HOST_HEADERS = [
		{
			headerLabel: t('User Name'),
			width: '20.73%',
		},
		{
			headerLabel: t('Rating'),
			width: '10%',
		},
		{
			headerLabel: t('Gender'),
			width: '8.11%',
		},
		{
			headerLabel: t('Followers'),
			width: '9.57%',
		},
		{
			headerLabel: t('City'),
			width: '15.32%',
		},
		{
			headerLabel: t('Country'),
			width: '15.85%',
		},
		{
			headerLabel: t('Events attended'),
			width: '9.85%',
		},
	];

	const GUEST_HEADERS = [
		{
			headerLabel: t('User Name'),
			width: '20.73%',
		},
		{
			headerLabel: t('Gender'),
			width: '12.11%',
		},
		{
			headerLabel: t('City'),
			width: '15.32%',
		},
		{
			headerLabel: t('Country'),
			width: '15.85%',
		},
		{
			headerLabel: t('Events attended'),
			width: '9.85%',
		},
	];

	const user = useAppSelector(state => state.auth.loggedInUserDetails);
	const [filter, sort] = useAppSelector(state => {
		return [state.filter, state.sort];
	});

	useEffect(() => {
		dispatch(setCurrentScreen('/network'));
	}, []);

	useEffect(() => {
		dispatch(resetSort());
		dispatch(resetFilters());
	}, []);

	const LIMIT = 10;

	const [getUsersStats, { loading: userStatLoading }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted: data => {
				if (
					data &&
					data.getUserStats &&
					data.getUserStats.data &&
					data.getUserStats.success
				) {
					setAllStats([...data.getUserStats.data.data]);
				}
			},
			onError: error => {
				setAlert(error.message, 'danger');
			},
		}
	);

	useEffect(() => {
		getUsersStats({
			variables: {
				id: [...filterIds, ...allIds],
			},
		});
	}, [allIds, filterIds, allValues]);

	const getIncreaseCurrent = () => {
		switch (selectedTab) {
			case 'Hosts':
				return increaseAllHostCurrent();
			case 'Sellers':
				return increaseAllSellerCurrent();
			case 'Guests':
				return increaseAllConsumerCurrent();
			default:
				return [];
		}
	};

	const OnPressViewMore = () => {
		const increaseCurrent = getIncreaseCurrent();
		getCurrentRefetch(increaseCurrent);
	};

	const USER: TUserType = user?.typeOfAccount.toLowerCase() as TUserType;
	const [total, setTotal] = useState<number>(0);

	const [selectedTab, setSelectedTab] = useState('');

	const [requestSendBooleanArray, setRequestSendBooleanArray] = useState<
		TIsFollowMeRequestData[]
	>([]);

	const [
		unfollowrequestSendBooleanArray,
		setUnfollowrequestSendBooleanArray,
	] = useState<TIsFollowMeRequestData[]>([]);

	const [getIdsForRequestsSent, setGetIdsForRequestsSent] =
		useState<string[]>();

	React.useEffect(() => {
		setSelectedTab(getTabs(USER)[0]?.value);
	}, []);

	const onPress = (tab: string) => {
		if (selectedTab === tab) {
			return;
		} else {
			setSelectedTab(tab);
			setAllValues([]);
			setAllStats([]);
			setFilterIds([]);
			setAllIds([]);
			setHostFilterData([]);
		}
	};
	const getCurrentRefetch = (current: any) => {
		switch (selectedTab) {
			case 'Hosts':
				return refetchAllHosts({
					limit: LIMIT,
					current: current,
				});
			case 'Sellers':
				return sellerRefetch({
					// sort: { ...sortCheck(sort) },
					limit: LIMIT,
					current: current,
				});
			case 'Guests':
				return refetchConsumers({
					// sort: { ...sortCheck(sort) },
					limit: LIMIT,
					current: current,
				});
			default:
				return [];
		}
	};

	const [
		getAllHosts,
		{
			loading: allHostsLoading,
			refetch: refetchAllHosts,
			networkStatus: hostNetStat,
		},
	] = useLazyQuery(
		GET_ALL_USERS,

		{
			variables: {
				find: {
					typeOfAccount: 'HOST',
				},
			},
			fetchPolicy: 'no-cache',
			notifyOnNetworkStatusChange: true,

			onCompleted: async data => {
				if (
					data.getAllUsers &&
					data.getAllUsers.data &&
					data.getAllUsers.success
				) {
					let x: any[] = data.getAllUsers.data.data.filter(
						(user: any) => user._id !== undefined
					);

					setTotal(data.getAllUsers.data.total);
					setAllIds([...allIds, ...x.map(user => user._id)]);
					x = x.filter(item => {
						const x = allHosts.find(
							element => element._id === item._id
						);
						if (!x) {
							return item;
						}
					});

					setAllHosts([...allHosts, ...x]);
					if (!allValues.length) {
						setAllValues([...x]);
					}
				}
			},
		}
	);

	const [
		getAllSellers,
		{
			loading: allSellersLoading,
			refetch: sellerRefetch,
			networkStatus: sellerNetStat,
		},
	] = useLazyQuery(GET_ALL_USERS, {
		variables: {
			find: {
				typeOfAccount: 'SELLER',
			},
		},
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: async data => {
			if (
				data.getAllUsers &&
				data.getAllUsers.data &&
				data.getAllUsers.success
			) {
				let x: any[] = data.getAllUsers.data.data.filter(
					(user: any) => user._id !== undefined
				);
				setTotal(data.getAllUsers.data.total);
				setAllIds([...allIds, ...x.map(user => user._id)]);

				x = x.filter(item => {
					const x = allSellers.find(
						element => element._id === item._id
					);
					if (!x) {
						return item;
					}
				});

				setAllSellers([...allSellers, ...x]);
				if (!allValues.length) {
					setAllValues([...x]);
				}
			}
		},
	});

	const [
		getAllConsumers,
		{
			loading: allConsumersLoading,
			refetch: refetchConsumers,
			networkStatus: consumerNetStat,
		},
	] = useLazyQuery(GET_ALL_USERS, {
		variables: {
			find: {
				typeOfAccount: 'CONSUMER',
			},
		},
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: async data => {
			if (
				data.getAllUsers &&
				data.getAllUsers.data &&
				data.getAllUsers.success
			) {
				let x: any[] = data.getAllUsers.data.data.filter(
					(user: any) => user._id !== undefined
				);
				setTotal(data.getAllUsers.data.total);
				setAllIds([...allIds, ...x.map(user => user._id)]);
				x = x.filter(item => {
					const x = allConsumers.find(
						element => element._id === item._id
					);
					if (!x) {
						return item;
					}
				});

				setAllConsumers([...allConsumers, ...x]);
				if (!allValues.length) {
					setAllValues([...x]);
				}
			}
		},
	});
	const [
		getAllSelectedHosts,
		{
			loading: allSelectedHostsLoading,
			networkStatus: SelectedhostNetStat,
		},
	] = useLazyQuery(GET_ALL_HOSTS, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: async data => {
			if (
				data.getAllHosts &&
				data.getAllHosts.data &&
				data.getAllHosts.success
			) {
				let x: any[] = data.getAllHosts.data.data.filter(
					(user: any) => user._id !== undefined
				);

				setFilterIds([...filterIds, ...x.map(user => user._id)]);
				setHostFilterData([...x]);
			}
		},
	});

	const [
		getAllSelectedSellers,
		{
			loading: allSelectedSellersLoading,
			networkStatus: SelectedsellerNetStat,
		},
	] = useLazyQuery(GET_ALL_SELLERS, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: async data => {
			if (
				data.getAllSellers &&
				data.getAllSellers.data &&
				data.getAllSellers.success
			) {
				let x: any[] = data.getAllSellers.data.data.filter(
					(user: any) => user._id !== undefined
				);
				setFilterIds([...filterIds, ...x.map(user => user._id)]);
				setHostFilterData([...x]);
			}
		},
	});

	const [
		getAllSelectedConsumers,
		{
			loading: allSelectedConsumersLoading,
			networkStatus: SelectedconsumerNetStat,
		},
	] = useLazyQuery(GET_ALL_CONSUMERS, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,

		onCompleted: async data => {
			if (
				data.getAllConsumers &&
				data.getAllConsumers.data &&
				data.getAllConsumers.success
			) {
				let x: any[] = data.getAllConsumers.data.data.filter(
					(user: any) => user._id !== undefined
				);
				setFilterIds([...filterIds, ...x.map(user => user._id)]);
				setHostFilterData([...x]);
			}
		},
	});

	const refetchRequestSentStatusQuery = [
		{
			query: IS_FOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: getIdsForRequestsSent ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
		{
			query: IS_UNFOLLOWING_ME_REQUEST_SENT,
			variables: {
				args: {
					ids: getIdsForRequestsSent ?? [],
				},
			} as TIsFollowMeRequestSentInputs,
		},
	];

	const [followMe, { loading: loadingFollowMe }] = useMutation<
		TFollowMeResponse,
		TFollowMeInputs
	>(FOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: refetchRequestSentStatusQuery,
		onCompleted: data => {
			if (data.FollowMe.success && data.FollowMe.statusCode === 200) {
				setAlert(t('Follow request sent!'), 'normal');
			} else {
				setAlert(t('Unable to send follow request!'), 'danger');
			}
		},
		onError: () => {
			setAlert(t('Unable to send follow request!'), 'danger');
		},
	});

	// const isFollowingMeArray = useAppSelector(state => state.user.isFollowingMe)
	// isFollowingMeArray.find(i => i.user)

	const [
		followMeUnfollowMeButtonPressedId,
		setFollowMeUnfollowMeButtonPressedId,
	] = useState('');

	const onClickFollowMe = async (
		recipientId: string,
		hasRequestBeenSent: boolean | undefined,
		index?: number
	) => {
		const data = [...getTabData(), ...allValues];
		const user = data?.find((user, index) => user._id === recipientId);

		// if (user === undefined) {
		// 	setAlert(t('Unable to send request'), 'danger');
		// }

		user && setSelectednow(user?._id);
		setFollowMeUnfollowMeButtonPressedId(user?._id ?? '');
		const variables = {
			args: {
				recipientId,
			},
		};

		hasRequestBeenSent
			? //  || isFollowingMe
			  unFollowMe({
					variables,
			  })
			: followMe({
					variables,
			  });
	};

	const [followmeIds, setFollowmeIds] = useState<string[]>([]);

	const [unFollowMe, { loading: loadingUnfollowme }] = useMutation<
		TUnfollowMeResponse,
		TUnfollowMeInputs
	>(UNFOLLOW_ME, {
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: IS_FOLLOWING_ME,
				variables: {
					args: {
						followeMeIds: followmeIds,
					},
				},
			},
		],
		onCompleted: data => {
			if (data.UnFollowMe.success && data.UnFollowMe.statusCode === 200) {
				setAlert(
					t('Successfully removed user from your followers'),
					'normal'
				);
			} else {
				setAlert(
					t('Unable to remove user from your followers'),
					'danger'
				);
			}
		},
		onError: () => {
			setAlert(t('Unable to remove user from your followers'), 'danger');
		},
	});
	const [isFollowedIds, setIsFollowedIds] = useState<string[]>([]);
	const getTabData = () => {
		switch (selectedTab) {
			case 'Hosts':
				return allHosts;
			case 'Sellers':
				return allSellers;
			case 'Guests':
				return allConsumers;
			default:
				return [];
		}
	};
	const getnetworkStatus = () => {
		switch (selectedTab) {
			case 'Hosts':
				return hostNetStat;
			case 'Sellers':
				return sellerNetStat;
			case 'Guests':
				return consumerNetStat;
			default:
				return [];
		}
	};

	useEffect(() => {
		const dataArray: TODO[] = [...allValues.map(user => user._id)];
		if (getTabData()) {
			const data = [...getTabData(), ...hostFilteredData];
			data?.map((item: TODO) => dataArray.push(item._id));
		}

		selectedTab === 'Guests' && setFollowmeIds(dataArray);
		selectedTab === 'Guests' && dispatch(setFollowMeIds(followmeIds));

		(selectedTab === 'Sellers' || selectedTab === 'Hosts') &&
			setIsFollowedIds(dataArray);
		(selectedTab === 'Sellers' || selectedTab === 'Hosts') &&
			dispatch(setNetworkFollowedIds(dataArray));
	}, [getTabData(), hostFilteredData, allValues]);
	const [checkIsFollowingMe] = useLazyQuery(IS_FOLLOWING_ME, {
		variables: {
			args: {
				followeMeIds: followmeIds,
			},
		},
		onCompleted: data => {
			data &&
				dispatch(
					setIsFollowingMeResult(data.IsFollowingMe?.data?.data)
				);
		},
	});

	const guestFunctions = () => {
		checkIsFollowingMe();
	};

	useEffect(() => {
		selectedTab === 'Guests' && guestFunctions();
	}, [followmeIds]);

	const isFollowingMeResult = useAppSelector(
		state => state.user.isFollowingMe
	);
	const getUserByTab = (value: string, filter: any) => {
		switch (value) {
			case 'Hosts':
				return () => {
					setCurrentHost(0);
					setAllHosts([]);
					getAllHosts({
						variables: {
							find: {
								typeOfAccount: 'HOST',
								...filter,
							},
							sort: { ...sortCheck(sort) },
							current: 0,
							limit: LIMIT,
						},
					});
				};
			case 'Sellers':
				return () => {
					setCurrentSeller(0);
					setAllSellers([]);
					getAllSellers({
						variables: {
							find: {
								typeOfAccount: 'SELLER',
								...filter,
							},
							sort: { ...sortCheck(sort) },
							current: 0,
							limit: LIMIT,
						},
					});
				};
			case 'Guests':
				return () => {
					setCurrentConsumer(0);
					setAllConsumers([]);
					getAllConsumers({
						variables: {
							find: {
								typeOfAccount: 'CONSUMER',
								...filter,
							},
							sort: { ...sortCheck(sort) },
							current: 0,
							limit: LIMIT,
						},
					});
				};
			default:
				return () => {};
		}
	};
	useEffect(() => {
		getUserByTab(selectedTab, filter)();
	}, [selectedTab, filter, sort]);
	useEffect(() => {
		const data = getTabData();
		if (!!data) {
			let _getIdsForRequestsSent: any[] = [
				...allValues.map(user => user._id),
			];
			data.map((item: any) => _getIdsForRequestsSent.push(item._id));
			setGetIdsForRequestsSent(_getIdsForRequestsSent);
			getIfRequestHaveBeenSent({
				variables: {
					args: {
						ids: _getIdsForRequestsSent,
					},
				},
			});
			getIfUnfollowMeRequestHaveBeenSent({
				variables: {
					args: {
						ids: _getIdsForRequestsSent,
					},
				},
			});
		}
	}, [getTabData()]);
	const [getIfRequestHaveBeenSent] = useLazyQuery<
		TIsFollowMeRequestSentResponse,
		TIsFollowMeRequestSentInputs
	>(IS_FOLLOWING_ME_REQUEST_SENT, {
		onCompleted: data => {
			if (
				data.IsFollowMeRequestSent.data &&
				data.IsFollowMeRequestSent.success
			) {
				setRequestSendBooleanArray(
					data.IsFollowMeRequestSent.data.data
				);
			}
		},
		onError: () => {},
	});

	const [
		getIfUnfollowMeRequestHaveBeenSent,
		{ loading: loadingIsUnFollowingMeRequestSent },
	] = useLazyQuery<
		TIsUnFollowMeRequestSentResponse,
		TIsFollowMeRequestSentInputs
	>(IS_UNFOLLOWING_ME_REQUEST_SENT, {
		onCompleted: data => {
			if (
				data.IsUnFollowMeRequestSent.data &&
				data.IsUnFollowMeRequestSent.success
			) {
				setUnfollowrequestSendBooleanArray(
					data.IsUnFollowMeRequestSent.data.data
				);
			}
		},
		onError: () => {},
	});

	/******************** END  -  API - isFollowMeRequestSent ***********************/

	// is follow api

	const [isFollowingBooleanArray, setIsFollowingBooleanArray] = useState<
		TIsFollowingData[]
	>([]);

	const [isFollowingUser] = useLazyQuery<
		TIsFollowingResponse,
		TIsFollowingInputs
	>(IS_FOLLOWING, {
		fetchPolicy: 'network-only',
		onCompleted: data => {
			if (data?.IsFollowing?.success && data?.IsFollowing?.data) {
				setIsFollowingBooleanArray(data.IsFollowing.data.data);
			}
		},
	});

	const runQuery = async (userIds: string[]) => {
		isFollowingUser({
			variables: {
				args: {
					followedIds: userIds,
					type: 'USER',
				},
			},
		});
	};

	useEffect(() => {
		if (!!isFollowedIds) {
			runQuery(isFollowedIds);
			// getUsersStats({
			// 	variables:{id:userIds}
			// })
		}
	}, [isFollowedIds]);

	// Get respective tab data
	const [
		executeUserByCategory,
		{ data: selectedCategoryData, loading: loadingFilteredData },
	] = useLazyQuery(GET_USER_CATEGORY, {
		fetchPolicy: 'cache-and-network',
		onCompleted: (data: any) => {
			if (
				data.getUsersByFavouriteCategories &&
				data.getUsersByFavouriteCategories.data &&
				data.getUsersByFavouriteCategories.success
			) {
				let x: any[] =
					data.getUsersByFavouriteCategories.data.data.filter(
						(user: any) => user._id !== undefined
					);
				setFilterIds([...filterIds, ...x.map(user => user._id)]);
				setHostFilterData([...x]);
			}
		},
	});

	/******************** START - API - filterBy ***********************/

	/******************** START - API - filterBy ***********************/

	const [
		executeUserByFollowers,
		{ data: selectedFollowerData, loading: followerDataLoading },
	] = useLazyQuery(GET_USER_FOLLOWERS, {
		fetchPolicy: 'cache-and-network',
		onCompleted: (data: any) => {
			if (
				data.getFollowers &&
				data.getFollowers.data &&
				data.getFollowers.success
			) {
				let x: any[] = data.getFollowers.data.data.filter(
					(user: any) => user._id !== undefined
				);
				setFilterIds([...filterIds, ...x.map(user => user._id)]);
				setHostFilterData([...x]);
			}
		},
	});
	const getSelectedCategoryQuery = (
		selectedCategory: string,
		typeOfAccount: string
	) => {
		switch (selectedCategory) {
			case 'my-followers':
				return executeUserByFollowers({ variables: { typeOfAccount } });
			case 'all':
				break;
			case 'selected-for-you':
				return selectedTab === 'Guests'
					? getAllSelectedConsumers()
					: selectedTab === 'Hosts'
					? getAllSelectedHosts()
					: getAllSelectedSellers();
			default:
				return executeUserByCategory({
					variables: { typeOfAccount, category: selectedCategory },
				});
		}
	};
	useEffect(() => {
		dispatch(resetFilters());
		dispatch(resetSort());
	}, [selectedTab]);
	useFocusEffect(
		useCallback(() => {
			// Do something when the screen is focused

			return () => {
				// Do something when the screen is unfocused
				// Useful for cleanup functions
				dispatch(resetSort());
				dispatch(resetFilters());
			};
		}, [])
	);

	useEffect(() => {
		const typeOfAccount =
			selectedTab === 'Guests'
				? 'CONSUMER'
				: selectedTab === 'Hosts'
				? 'HOST'
				: 'SELLER';
		getSelectedCategoryQuery(selectedCategory, typeOfAccount);
	}, [selectedCategory, selectedTab]);

	const [isFilteredData, setIsFilteredData] = useState(false);

	const setSelectednow = async (userId: string) => {
		await setSelected(userId);
	};
	const addStats = (data: any[]): any[] => {
		return data.map(datum => ({
			...datum,
			city: datum.personalInformation.address?.city,
			countryLabel: datum.personalInformation.address?.countryLabel,
			followersCount:
				allStats?.find(
					(val: { userId: string }) => val.userId === datum._id
				)?.followersCount || 0,
			eventsCount:
				allStats?.find(
					(val: { userId: string }) => val.userId === datum._id
				)?.eventsCount || 0,
			productsCount:
				allStats?.find(
					(val: { userId: string }) => val.userId === datum._id
				)?.productsCount || 0,
		}));
	};

	const loadingState = useMemo(
		() =>
			getSingleLoadingState(
				loadingFilteredData,
				followerDataLoading,
				allSelectedConsumersLoading,
				allSelectedHostsLoading,
				allSelectedSellersLoading
			),
		[
			loadingFilteredData,
			followerDataLoading,
			allSelectedConsumersLoading,
			allSelectedHostsLoading,
			allSelectedSellersLoading,
		]
	);
	const tabDataLoadingState = useMemo(
		() =>
			getSingleLoadingState(
				allHostsLoading,
				allSellersLoading,
				allConsumersLoading
			),
		[allHostsLoading, allSellersLoading, allConsumersLoading]
	);

	return shouldHideScreen ? null : (
		<DesktopTemplate>
			<Tabs
				onPress={onPress}
				selectedTab={selectedTab}
				tabs={getTabs(USER)}
			/>
			<FilterCategories
				onClick={category => {
					if (selectedCategory === category) {
						return;
					} else {
						setFilterIds([]);
						setHostFilterData([]);
						setSelectedCategory(category);

						if (category === 'all') {
							setIsFilteredData(false);
							return;
						}
						setIsFilteredData(true);
					}
				}}
				dropdownCategories={DROPDOWN_CATEGORIES}
				pillCategories={[...PILL_INIT_CATEGORIES, ...PILL_CATEGORIES]}
				selectedCategory={selectedCategory}
				secondLabel={`${t(selectedTab)}`}
			/>
			{/* {isFilteredData && !hostFilteredData.length && !loadingState && (
				<NotAvailableMessage message={t('No Result Found!')} />
			)} */}

			<ProfileCards
				data={
					isFilteredData
						? addStats(hostFilteredData)
						: addStats(allValues)
				}
				hasActionBtn
				hasRating={selectedTab !== 'Guests'}
				hasEventAttendedOnly={selectedTab === 'Guests'}
				loading={isFilteredData ? loadingState : tabDataLoadingState}
				requestSendBooleanArray={requestSendBooleanArray}
				onClickHostFollowMeActionButton={onClickFollowMe}
				type={
					selectedTab === 'Guests'
						? 'consumer'
						: selectedTab === 'Hosts'
						? 'host'
						: 'seller'
				}
				unfollowrequestSendBooleanArray={
					unfollowrequestSendBooleanArray
				}
				followMeUnfollowMeButtonPressedId={
					followMeUnfollowMeButtonPressedId
				}
				isFollowingBooleanArray={isFollowingBooleanArray}
				loadingFollowMe={loadingFollowMe || loadingUnfollowme}
				setSelected={setSelectednow}
			/>
			{tabDataLoadingState && !getTabData().length ? (
				<Loader />
			) : (
				<>
					<ProfileTableControl
						heading={selectedTab}
						data={addStats(getTabData()).sort((a, b) =>
							sortValue(a, b, sort)
						)}
						page="network"
						setSelected={setSelectednow}
						hasFilter
						filterItem={networkFilters}
						sortItem={
							selectedTab === 'Guests'
								? generalNetworkSort
								: [...generalNetworkSort, ...networkHostSort]
						}
						type={selectedTab === 'Guests' ? 'consumer' : 'host'}
						selectedTab={selectedTab}
						messageButton={false}
						followButton={true}
						headers={
							selectedTab === 'Guests'
								? GUEST_HEADERS
								: HOST_HEADERS
						}
						requestSendBooleanArray={requestSendBooleanArray}
						unfollowrequestSendBooleanArray={
							unfollowrequestSendBooleanArray
						}
						onClickHostFollowMeActionButton={onClickFollowMe}
						followMeUnfollowMeButtonPressedId={
							followMeUnfollowMeButtonPressedId
						}
						loadingFollowMe={loadingFollowMe || loadingUnfollowme}
						isFollowingBooleanArray={isFollowingBooleanArray}
					/>
					{getTabData().length < total && (
						<LoadMore
							onClick={OnPressViewMore}
							loading={getnetworkStatus() === 2}
						/>
					)}
				</>
			)}
		</DesktopTemplate>
	);
};

export default NetworkScreen;
