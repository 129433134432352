import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button } from '../..';
import { useGetAllBrands } from '../../../api/brands/useGetAllBrands';
import { useNextStepScreen } from '../../../hooks/useNextStepScreen';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ModalComponent from '../../common/modal/ModalComponent';
import { EventBrandSearch } from './EventBrandSearch';
import { SelectedBrands } from './selected-brands';
import { SidebarBrandDetails } from './SidebarBrandDetails';

const EventProductForm = () => {
	const { t } = useTranslation();

	const createEventSidebarArray = useAppSelector(
		state => state.ui.CreateEventSidebarArray
	);
	const { goToNextStepScreen } = useNextStepScreen(createEventSidebarArray);

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const [
		selectedBrandToDisplayInSidebar,
		setSelectedBrandToDisplayInSidebar,
	] = useState<TGetAllBrandsData | undefined>();

	const [selectedBrands, setSelectedBrands] = useState<TGetAllBrandsData[]>(
		[]
	);

	const isEditEvent = useAppSelector(state => state.getEvent.isEditEvent);
	const event = useAppSelector(state => state.getEvent.event);

	const onPressToSelectBrand = (brandSelected: TGetAllBrandsData) => {
		setSelectedBrandToDisplayInSidebar(brandSelected);
		setIsSidebarOpen(true);
	};

	/************************* API ********************** */

	const onCompletedGetAllBrands: TOnCompleteApiCall<
		TGetAllBrandsResponse
	> = data => {};

	const { data: allBrands, loading: getAllBrandsLoading } = useGetAllBrands({
		onCompleted: onCompletedGetAllBrands,
	});

	useEffect(() => {
		if (isEditEvent) {
			setSelectedBrands(event.affiliatedBrands as TGetAllBrandsData[]);
		}
	}, []);

	return (
		<View>
			<ModalComponent
				header={t('All Products')}
				onClose={() => setIsSidebarOpen(false)}
				openState={isSidebarOpen}
				type="sidebar"
			>
				<SidebarBrandDetails
					brandDetails={selectedBrandToDisplayInSidebar}
				/>
			</ModalComponent>

			<View style={[styleRowContainer(), { zIndex: 1 }]}>
				<EventBrandSearch
					{...{
						isSidebarOpen,
						setIsSidebarOpen,
						setselectedBrandToDisplayInSidebar:
							setSelectedBrandToDisplayInSidebar,
						onPressToSelectBrand,
						allBrands,
						loading: getAllBrandsLoading,
						selectedBrands,
						setSelectedBrands,
					}}
				/>
			</View>
			{/* <RecommendedBrands
				setIsSidebarOpen={setIsSidebarOpen}
				onPressToSelectBrand={onPressToSelectBrand}
			/> */}
			{selectedBrands.length > 0 && (
				<SelectedBrands
					selectedBrands={selectedBrands}
					setSelectedBrands={setSelectedBrands}
					onPressBrandContainer={onPressToSelectBrand}
				/>
			)}
			<Button
				title={t('Next')}
				variant={selectedBrands.length > 0 ? 'primary' : 'disabled'}
				size="lg"
				outerContainerProps={{
					style: {
						marginTop: getResponsiveStyle(46),
					},
				}}
				onPress={goToNextStepScreen}
			/>
		</View>
	);
};

export default EventProductForm;

const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(40),
	} as TStyle;
};
