import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../config';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { usePrimaryColors } from '../hooks/usePrimaryColors';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../types/exportedTypes';
import { setWasCardUsed } from '../redux/userSlice';
import { useMutation } from '@apollo/client';
import { ATTACH_CARD_TO_USER } from '../graphql/stripe/mutations';
import { getCountryLabelFromValue } from '../utils/getCountryLabelFromValue';
import { handleNavigationToEvent } from '../utils/handleNavigationToEvent';
import {
	SelectedVoucher,
	setSelectedCardFn,
	setSelectedVoucher,
} from '../redux/walletSlice';
import { TCard } from '../components/profile/Profile-Wallet/CardSection';
import { setCurrentScreen } from '../redux/uiSlice';

const PurchaseCompleteScreen = () => {
	const { isLessThanDesktopBase, isTabletView, isMobileView } =
		useIsSpecificViewportWidth();
	const { t } = useTranslation();
	const primary = usePrimaryColors();

	const billingAddressLine = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.address1
	);
	const billingAddressCity = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.city
	);
	const billingAddressZipCode = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation.address.zipCode
	);

	const selectedVoucher = useAppSelector(
		state => state.wallet.selectedVoucher
	);
	const selectedCard = useAppSelector(state => state.wallet.selectedCard);
	const typeOfAccount = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const wasCardUsed = useAppSelector(state => state.user.wasCardUsed);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/purchase-complete'));
	}, []);

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const payingCardDeets = useAppSelector(state => state.oneStopCheckout.card);

	const navigation = useNavigation<TUseNavigation>();

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	const eventPurchasedFrom = useAppSelector(
		state => state.user.buyingFromEvent
	);
	const [attachPaymentToCustomer, { loading: attachPaymentLoading }] =
		useMutation(ATTACH_CARD_TO_USER);

	// const saveForLaterUse = async () => {
	// 	const addressArgs = {
	// 		address: payingAddressDeets.address,
	// 		city: payingAddressDeets.city,
	// 		countery: payingAddressDeets.countery,
	// 		name: payingAddressDeets.name,
	// 		zipCode: payingAddressDeets.zipCode,
	// 	};

	// 	if (payingAddressDeets.countery) {
	// 		await createAddressBook({
	// 			variables: {
	// 				args: addressArgs,
	// 			},
	// 		});
	// 	}

	// 	if (createdCardId) {
	// 		await attachPaymentToCustomer({
	// 			variables: {
	// 				args: {
	// 					id: createdCardId,
	// 					customerId: userId,
	// 				},
	// 			},
	// 		});
	// 	}

	// 	setDialogueState(false);
	// };

	const handleNavigation = () => {
		if (eventPurchasedFrom) {
			handleNavigationToEvent({
				navigation,
				dispatch,
				loggedInUserDetails,
				event: eventPurchasedFrom,
			});
		} else {
			navigation.navigate('Home');
		}
	};
	return (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			<View>
				{/* <View> */}
				<Text style={styles.text1}>{t('Purchase Complete!')}</Text>
				<Text style={styles.text2}>
					{t('Thank you for your purchase.')}
				</Text>

				<View
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'row',
					}}
				>
					<View
						style={[
							{
								maxWidth: !isLessThanDesktopBase
									? '32.5%'
									: isTabletView
									? '45%'
									: '100%',
							},
							{ marginBottom: 10 },
						]}
					>
						<Text
							style={{
								fontWeight: '700',
								marginTop: getResponsiveStyle(50),
								fontSize: 17,
								marginBottom: 10,
							}}
						>
							{t('Shipping Address')}
						</Text>
						<View
							style={[
								styles.addressContainer,
								[
									{
										width: isMobileView ? 300 : 400,
									},
								],
							]}
						>
							{(payingAddressDeets.name ||
								loggedInUserDetails?.username) && (
								<View style={styles.view_}>
									<Text style={styles.text_}>
										{t('Name')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{payingAddressDeets.name ||
											loggedInUserDetails?.username}
									</Text>
								</View>
							)}

							<View style={[{ marginTop: 20 }, styles.view_]}>
								<Text style={styles.text_}>{t('Country')}</Text>
								<Text style={{ color: '#101625' }}>
									{getCountryLabelFromValue(
										payingAddressDeets.countery
									)}
								</Text>
							</View>
							<View style={[{ marginTop: 20 }, styles.view_]}>
								<Text style={[{ marginTop: 10 }, styles.text_]}>
									{t('Address')}
								</Text>
								<Text style={{ color: '#101625' }}>
									{payingAddressDeets.address}
								</Text>
							</View>
							<View
								style={[
									{
										marginTop: 20,
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									},
									styles.view_,
								]}
							>
								<View>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('City')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{payingAddressDeets.city}
									</Text>
								</View>
								<View>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('Zip Code')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{payingAddressDeets.zipCode}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>

				{typeOfAccount === 'HOST' && (
					<View
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'row',
						}}
					>
						<View
							style={[
								{
									maxWidth: !isLessThanDesktopBase
										? '32.5%'
										: isTabletView
										? '45%'
										: '100%',
								},
								{ marginBottom: 10 },
							]}
						>
							<Text
								style={{
									fontWeight: '700',
									marginTop: getResponsiveStyle(50),
									fontSize: 17,
									marginBottom: 10,
								}}
							>
								{t('Vouchers')}
							</Text>

							<View
								style={[
									styles.addressContainer,
									[
										{
											width: isMobileView ? 300 : 400,
										},
									],
								]}
							>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<View>
										<Text style={styles.text_}>
											{t('Brand Name')}
										</Text>
										<Text style={{ color: '#101625' }}>
											{selectedVoucher?.brandId?.name}
										</Text>
									</View>

									<View>
										<Text
											style={[
												{ marginTop: 10 },
												styles.text_,
											]}
										>
											{t('Location')}
										</Text>
										<Text style={{ color: '#101625' }}>
											{
												selectedVoucher?.brandId
													?.address?.countryLabel
											}
										</Text>
									</View>
								</View>
								<View style={[{ marginTop: 20 }, styles.view_]}>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('Balance')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{parseFloat(
											selectedVoucher?.amount
										).toFixed(2)}
									</Text>
								</View>
								<View style={[{ marginTop: 20 }, styles.view_]}>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('Description')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{t(
											'Reward for hosting events of brand '
										)}{' '}
										{selectedVoucher?.brandId?.name}
									</Text>
								</View>
							</View>
						</View>
					</View>
				)}

				{wasCardUsed && (
					<View
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'row',
						}}
					>
						<View
							style={[
								{
									maxWidth: !isLessThanDesktopBase
										? '32.5%'
										: isTabletView
										? '45%'
										: '100%',
								},
							]}
						>
							<Text
								style={{
									fontWeight: '700',
									marginTop: getResponsiveStyle(50),
									fontSize: 17,
									marginBottom: 10,
								}}
							>
								{t('Payment Method')}
							</Text>
							<View
								style={[
									styles.addressContainer,
									[
										{
											width: isMobileView ? 300 : 400,
										},
									],
								]}
							>
								<View style={styles.view_}>
									<Text style={styles.text_}>
										{t('Card Holder Name')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{selectedCard?.name
											? selectedCard.name
											: payingCardDeets?.name}
									</Text>
								</View>
								<View style={[{ marginTop: 20 }, styles.view_]}>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('Billing Address')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{`${billingAddressLine}, ${billingAddressCity}, ${billingAddressZipCode}`}
									</Text>
								</View>
								<View style={[{ marginTop: 20 }, styles.view_]}>
									<Text
										style={[
											{ marginTop: 10 },
											styles.text_,
										]}
									>
										{t('Payment Method')}
									</Text>
									<Text style={{ color: '#101625' }}>
										{selectedCard.number
											? selectedCard.number
											: payingCardDeets.cardNo}
									</Text>
								</View>
							</View>
						</View>
					</View>
				)}
				<View
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'row',
					}}
				>
					<View
						style={[
							{
								maxWidth: !isLessThanDesktopBase
									? '32.5%'
									: isTabletView
									? '45%'
									: '100%',
							},
						]}
					>
						<Text
							onPress={() => {
								dispatch(setWasCardUsed(false));
								dispatch(
									setSelectedVoucher({} as SelectedVoucher)
								);
								dispatch(setSelectedCardFn({} as TCard));
								handleNavigation();
							}}
							style={[
								{ backgroundColor: primary[0] },
								styles.continueText,
								{
									marginBottom: isLessThanDesktopBase
										? 150
										: 30,
									width: isMobileView ? 300 : 400,
								},
							]}
						>
							{t('Continue Shopping')}
						</Text>
					</View>
				</View>
			</View>
			{/* 
			<ModalComponent
				header={t('Save details for later use')}
				openState={false}
				onClose={() => {
					setDialogueState(false);
				}}
				onCheckout={true}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Button
						variant="primary"
						size="lg"
						title={t('Accept')}
						onPress={() => saveForLaterUse()}
						loading={
							createAddressBookLoading || attachPaymentLoading
						}
					/>
					<Button
						variant="grey"
						size="lg"
						title={t('Decline')}
						onPress={() => setDialogueState(false)}
					/>
				</View>
			</ModalComponent> */}
		</DesktopTemplate>
	);
};

export default PurchaseCompleteScreen;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		// alignItems: 'center',
		width: '100%',
		// height: '100vh'
	},
	text1: {
		color: '#101625',
		textAlign: 'center',
		fontWeight: '700',
		fontSize: 34,
		lineHeight: 32,
	},
	text2: {
		color: '#616E82',
		textAlign: 'center',
	},
	text_: {
		color: '#98A2B2',
	},
	view_: {
		// marginTop: 20
	},
	addressContainer: {
		paddingTop: getResponsiveStyle(28),
		padding: getResponsiveStyle(24),
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		// maxWidth: getResponsiveStyle(440),
		// width: getResponsiveStyle(400),
	},

	continueText: {
		color: 'white',
		borderRadius: 10,
		// width: getResponsiveStyle(400),

		marginTop: 20,
		height: 48,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
