import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { REMOVE_FROM_CART } from '../../../graphql/cart/mutation';
import { GET_CART } from '../../../graphql/cart/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { FOUR_HOURS } from '../../../utils/cartExpiry';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CartIcon } from '../icons';
import ModalComponent from '../modal/ModalComponent';
import Spacing from '../spacing/Spacing';
import CartList from './Cart-List/CartList';
import { TStyle } from '../../../types/TStyle';
import { setGlobalCartOpen } from '../../../redux/userSlice';

type Props = {
	hasText?: boolean;
	black?: boolean;
	white?: boolean;
	containerStyle?: TStyle;
	cartIconWidth?: number;
	cartIconHeight?: number;
};
export const CartIconWithItems = ({
	hasText,
	black,
	white,
	containerStyle,
	cartIconWidth,
	cartIconHeight,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [cartModalState, setAddCartModalState] = React.useState(false);
	const changeCartModalState = () => {
		setAddCartModalState(!cartModalState);
		dispatch(setGlobalCartOpen(false));
	};
	const { isDesktop } = useIsSpecificViewportWidth();
	const primary = usePrimaryColors();
	const { visitorCartStore, globalCartOpen } = useAppSelector(
		state => state.user
	);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const userCart = isUserLoggedIn ? cart : visitorCartStore || [];

	const [removeItemFromCart] = useMutation(REMOVE_FROM_CART);

	const removeFunction = (item: TODO) => {
		removeItemFromCart({
			variables: {
				args: {
					items: {
						productId: item.productId._id,
					},
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [{ query: GET_CART }],
		});
	};

	useEffect(() => {
		userCart?.map(item => {
			const timeAvailableForPurchase =
				item.eventId &&
				parseInt(item?.eventId?.eventStreamTimeline?.endedAt) +
					FOUR_HOURS;
			item.eventId &&
				timeAvailableForPurchase >= Date.now() &&
				setTimeout(() => {
					removeFunction(item);
				}, timeAvailableForPurchase - Date.now());
		});
	}, [userCart]);

	return (
		<View
			style={[
				styles.container,
				mobileContainer(!isDesktop, !hasText),
				containerStyle,
			]}
		>
			<Pressable onPress={changeCartModalState}>
				{isDesktop ? (
					<CartIcon props={styleCartIcon()} />
				) : hasText ? (
					<Spacing>
						<CartIcon
							props={{
								width: '19.04',
								height: '16.85',
							}}
							fillColor={
								white ? palettes.light[1] : palettes.dark[4]
							}
						/>
						{t('Cart')}
					</Spacing>
				) : (
					<View>
						<CartIcon
							props={{
								width: getResponsiveStyle(
									cartIconWidth ? cartIconWidth : 20,
									'dimensions'
								),
								height: getResponsiveStyle(
									cartIconHeight ? cartIconHeight : 20,
									'dimensions'
								),
							}}
							fillColor={
								black
									? palettes.dark[4]
									: white
									? palettes.light[0]
									: palettes.grey[1]
							}
						/>
					</View>
				)}
			</Pressable>

			<Text style={[styles.itemsInCart, styleItemsInCart(primary)]}>
				{userCart?.length === 0
					? ''
					: userCart?.length < 10
					? `0${userCart?.length}`
					: userCart?.length}
			</Text>
			<ModalComponent
				type={!isDesktop ? 'fullpage' : 'sidebar'}
				header={`${t('My cart')}`}
				openState={cartModalState || globalCartOpen}
				onClose={changeCartModalState}
				subtitle={
					// userCart?.length === 0
					// 	? `${t("There's nothing in your cart")}`
					// 	: `${t("It's in the bag - We'll hold it for an hour")}`
					'Free delivery for purchases over 59€ in France. Safe and secure payment - 3D secure.'
				}
				contentMargin={1}
				hasNoClose={!!userCart?.length}
			>
				<CartList
					onCheckout={false}
					setModalState={changeCartModalState}
				/>
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	itemsInCart: {
		fontWeight: '600',
	},
});

function styleItemsInCart(primary: string[]) {
	return {
		marginLeft: getResponsiveStyle(2),
		marginRight: getResponsiveStyle(0),
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		color: primary?.[0],
		marginBottom: getResponsiveStyle(10),
	};
}

function styleCartIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(20, 'dimensions'),
		marginTop: 2,
	};
}
function mobileContainer(isMobile: boolean, noText: boolean) {
	return isMobile && noText && { alignItems: 'start' };
}
