import { useLazyQuery } from '@apollo/client';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useGetBrandStats } from '../api/brands/useGetBrandStats';
import {
	CompanyCard,
	FilterCategories,
	LoadMore,
	NotAvailableMessage,
	ProductCards,
} from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import {
	GET_ALL_BRANDS,
	GET_BRANDS_SELECTED_FOR_YOU,
	GET_BRAND_BY_TAG,
	GET_FOLLOWING_BRANDS,
	GET_NEW_BRANDS,
} from '../graphql/brands/queries';
import { IS_FOLLOWING } from '../graphql/network/queries';
import { usePagination } from '../hooks/useCurrentForPagination';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import BrandScreenLoader from '../components/common/skeleton-loaders/brand-loader/brandScreenLoader';
import { useSetAlert } from '../hooks/useSetAlerts';
import { twoDp } from '../utils/twoDP';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setCurrentScreen } from '../redux/uiSlice';

export type TIsFollowingHash = {
	[x in string]: boolean;
};

// const calculateTotalProductsPrice = (
// 	products: TGetAllProductsDataPaginated
// ) => {
// 	return products.data
// 		.map(product => product.variants[0].price)
// 		.reduce((a, b) => a + b, 0);
// };
const LIMIT = 10;

const BrandsScreen = () => {
	const { isDesktop } = useIsSpecificViewportWidth();
	const { shouldHideScreen } = useHandleUnmount();
	const { setAlert } = useSetAlert();
	const {
		DROPDOWN_CATEGORIES,
		BRAND_INIT_CATEGORIES,
		BRAND_PILL_CATEGORIES,
	} = useAppSelector(state => state.tags);
	const [isFollowingObject, setIsFollowingObject] =
		useState<TIsFollowingHash>();

	const [brandIds, setBrandIds] = useState<string[]>([]);

	const [brandStatsArray, setBrandStatsArray] = useState<BrandStats[]>();

	const [allBrands, setAllBrands] = useState<TGetAllBrandsData[]>([]);
	const [filterBrandData, setFilterBrandData] = useState<TGetAllBrandsData[]>(
		[]
	);

	const [totalBrands, setTotalBrands] = useState(0);
	const [brandLoading, setBrandLoading] = useState(false);

	const [currentPageForAllBrands, increasePageForAllBrands] = usePagination();
	const [current, increaseCurrent, setCurrent] = usePagination();

	const [tag, setTag] = useState('all');

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/brands'));
	}, []);

	/****************** API ********************** */

	const [
		getAllBrands,
		{
			refetch: refetchAllBrands,
			networkStatus: allBrandsNetworkStatus,
			loading: allBrandsLoading,
		},
	] = useLazyQuery<TGetAllBrandsResponse, Omit<TPaginatedOptions, 'find'>>(
		GET_ALL_BRANDS,
		{
			fetchPolicy: 'no-cache',
			onCompleted: (data: TGetAllBrandsResponse) => {
				if (
					data.getAllBrands &&
					data.getAllBrands.data &&
					data.getAllBrands.success
				) {
					setTotalBrands(data.getAllBrands.data.total);

					const _brandIds = data.getAllBrands.data.data.map(
						brand => brand._id
					);

					setBrandIds([...brandIds, ..._brandIds]);

					setAllBrands([
						...allBrands,
						...data.getAllBrands.data.data,
					]);

					getIsFollowingStatus({
						variables: {
							args: {
								followedIds: [...brandIds, ..._brandIds],
								type: 'BRAND',
							},
						},
					});
					setBrandLoading(false);
				}
			},
		}
	);
	const [
		getFollowingBrands,
		{
			loading: followingBrandLoading,
			refetch: refetchFollowingBrands,
			networkStatus: followingBrandsNetworkStatus,
		},
	] = useLazyQuery(GET_FOLLOWING_BRANDS, {
		notifyOnNetworkStatusChange: true,
		onCompleted: data => {
			if (
				data.getFollowingBrands &&
				data.getFollowingBrands.data &&
				data.getFollowingBrands.success
			) {
				setTotalBrands(data.getFollowingBrands.data.total);

				const _brandIds = data.getFollowingBrands.data.data.map(
					(brand: TGetAllBrandsData) => brand._id
				);

				setBrandIds([...brandIds, ..._brandIds]);

				setFilterBrandData([
					// ...filterBrandData,
					...data.getFollowingBrands.data.data,
				]);

				getIsFollowingStatus({
					variables: {
						args: {
							followedIds: [...brandIds, ..._brandIds],
							type: 'BRAND',
						},
					},
				});
			}
		},
	});
	const [
		getNewBrands,
		{
			refetch: refetchNewBrands,
			networkStatus: newBrandsNetworkStatus,
			loading: newBrandLoading,
		},
	] = useLazyQuery(GET_NEW_BRANDS, {
		notifyOnNetworkStatusChange: true,
		onCompleted: data => {
			if (
				data.getNewBrands &&
				data.getNewBrands.data &&
				data.getNewBrands.success
			) {
				setTotalBrands(data.getNewBrands.data.total);

				const _brandIds = data.getNewBrands.data.data.map(
					(brand: TGetAllBrandsData) => brand._id
				);

				setBrandIds([...brandIds, ..._brandIds]);

				setFilterBrandData([
					...filterBrandData,
					...data.getNewBrands.data.data,
				]);

				getIsFollowingStatus({
					variables: {
						args: {
							followedIds: [...brandIds, ..._brandIds],
							type: 'BRAND',
						},
					},
				});
			}
		},
	});
	const [
		getSelectedBrands,
		{
			refetch: refetchSelectedBrands,
			networkStatus: selectedNetworkStatus,
			loading: selectedLoading,
		},
	] = useLazyQuery(GET_BRANDS_SELECTED_FOR_YOU, {
		onCompleted: data => {
			if (
				data.getBrandsSelectedForYou &&
				data.getBrandsSelectedForYou.data &&
				data.getBrandsSelectedForYou.success
			) {
				setTotalBrands(data.getBrandsSelectedForYou.data.total);

				const _brandIds = data.getBrandsSelectedForYou.data.data.map(
					(brand: TGetAllBrandsData) => brand._id
				);

				setBrandIds([...brandIds, ..._brandIds]);

				setFilterBrandData([
					...filterBrandData,
					...data.getBrandsSelectedForYou.data.data,
				]);

				getIsFollowingStatus({
					variables: {
						args: {
							followedIds: [...brandIds, ..._brandIds],
							type: 'BRAND',
						},
					},
				});
			} else {
				setAlert(t('Unable to fetch brand'), 'danger');
			}
		},
		onError: error => {
			setAlert(error.message, 'danger');
		},
	});

	useEffect(() => {
		getAllBrands({
			variables: {
				current: currentPageForAllBrands,
				limit: LIMIT,
				productCurrent: 0,
				productLimit: 10,
			},
		});
	}, []);

	const { getBrandStats } = useGetBrandStats({
		onCompleted: (data: any) => {
			if (
				data &&
				data?.getBrandStats &&
				data?.getBrandStats?.success &&
				data?.getBrandStats?.data
			) {
				setBrandStatsArray(data.getBrandStats.data.data);
			}
		},
	});

	useEffect(() => {
		if (tag == 'all') {
			getBrandStats(allBrands?.map(brand => brand._id) as string[]);
		} else {
			getBrandStats(filterBrandData?.map(brand => brand._id) as string[]);
		}
	}, [allBrands, filterBrandData, tag]);

	/******************** START - API - isFollowing ***********************/

	const [getIsFollowingStatus] = useLazyQuery<
		TIsFollowingResponse,
		TIsFollowingInputs
	>(IS_FOLLOWING, {
		onCompleted: data => {
			if (
				data.IsFollowing &&
				data.IsFollowing.success &&
				data.IsFollowing.data
			) {
				const _isFollowingObjectHash: TIsFollowingHash =
					data.IsFollowing.data.data.reduce((result, item) => {
						return { ...result, [item.userId]: item.isFollowing };
					}, {});

				setIsFollowingObject(_isFollowingObjectHash);
			}
		},
	});

	/******************** END  -  API - isFollowing ***********************/

	/******************** START  -  API - brand filter ***********************/

	const [
		executeBrandByFilter,
		{
			loading: brandFilterLoading,
			networkStatus: brandfilterNetworkStatus,
		},
	] = useLazyQuery(
		GET_BRAND_BY_TAG,

		{
			notifyOnNetworkStatusChange: true,
			onCompleted: data => {
				const _brandIds = data.filterBrandsByTag?.data.data.map(
					(brand: TGetAllBrandsData) => brand._id
				);
				setTotalBrands(data.filterBrandsByTag?.data.data?.length);

				setBrandIds([...brandIds, ..._brandIds]);
				setFilterBrandData([
					...filterBrandData,
					...data.filterBrandsByTag?.data.data,
				]);

				getIsFollowingStatus({
					variables: {
						args: {
							followedIds: [...brandIds, ..._brandIds],
							type: 'BRAND',
						},
					},
				});
			},
		}
	);
	const fetchFiltered = (tag: string) => {
		switch (tag) {
			case 'new-brand':
				return getNewBrands({
					variables: {
						current: current,
						limit: LIMIT,
						productCurrent: 0,
						productLimit: 10,
					},
				});
			case 'followed-brand':
				return getFollowingBrands({
					variables: {
						productCurrent: 0,
						productLimit: 10,
					},
				});
			case 'selected-for-you':
				return getSelectedBrands({
					variables: {
						current: current,
						limit: LIMIT,
						productCurrent: 0,
						productLimit: 10,
					},
				});
			case 'all':
				return 0;
			default:
				executeBrandByFilter({
					variables: {
						tag,
						productCurrent: 0,
						productLimit: 10,
					},
				});
		}
	};

	useEffect(() => {
		setFilterBrandData([]);
		fetchFiltered(tag);
	}, [tag]);
	const handleBrandResult = () => {
		return tag === 'all' ? allBrands : filterBrandData;
	};

	/******************** END  -  API - brand filter ***********************/
	const isLoading = useMemo(
		() =>
			getSingleLoadingState(
				brandFilterLoading,
				newBrandLoading,
				selectedLoading,
				followingBrandLoading,
				allBrandsLoading
			),
		[
			brandFilterLoading,
			newBrandLoading,
			selectedLoading,
			allBrandsLoading,
			followingBrandLoading,
		]
	);

	const handleRefetch = async () => {
		// console.log({ tag });

		switch (tag) {
			case 'all': {
				const nextPage = increasePageForAllBrands();
				return refetchAllBrands({
					limit: LIMIT,
					current: nextPage,
					productCurrent: 0,
					productLimit: 10,
				});
			}

			case 'new-brand': {
				const nextPage = increaseCurrent();
				return refetchNewBrands({
					limit: LIMIT,
					current: nextPage,
					productCurrent: 0,
					productLimit: 10,
				});
			}
			case 'selected-for-you': {
				const nextPage = increaseCurrent();
				return refetchSelectedBrands({
					limit: LIMIT,
					current: nextPage,
					productCurrent: 0,
					productLimit: 10,
				});
			}
			// case 'followed-brand': {
			// 	const nextPage = increaseCurrent();
			// 	return refetchFollowingBrands({
			// 		limit: LIMIT,
			// 		current: nextPage,
			// 	});
			// }
			default: {
				return [];
			}
		}
	};
	const handleNetwork = () => {
		switch (tag) {
			case 'all': {
				return allBrandsNetworkStatus;
			}

			case 'new-brand': {
				return newBrandsNetworkStatus;
			}
			case 'followed-brand': {
				return followingBrandsNetworkStatus;
			}
			case 'selected-for-you': {
				return selectedNetworkStatus;
			}
			default:
				return brandfilterNetworkStatus;
		}
	};

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasCart: true,
			}}
		>
			<FilterCategories
				onClick={tag => {
					setCurrent(0);
					setTotalBrands(0);
					setTag(tag);
				}}
				selectedCategory={tag}
				dropdownCategories={DROPDOWN_CATEGORIES}
				pillCategories={[
					...BRAND_INIT_CATEGORIES,
					...BRAND_PILL_CATEGORIES,
				]}
				secondLabel={t('Brands')}
			/>

			{handleBrandResult().length ? (
				handleBrandResult()?.map(
					({
						_id,
						additionalMedia,
						address,
						name,
						products,
						profileImage,
						rating,
						description,
					}) => {
						const stats = brandStatsArray?.find(
							(brand: any) => brand.brandId === _id
						);
						return (
							<View
								key={_id}
								style={[
									styleCompanyProductCardLayout(isDesktop),
									styleCompanyProductRow(),
								]}
							>
								<CompanyCard
									_id={_id}
									address={address}
									name={name}
									profileImage={profileImage}
									additionalMedia={additionalMedia}
									rating={rating}
									description={description}
									products={products}
									tag={tag}
									brandStatsArray={stats}
									isFollowingObject={isFollowingObject}
									setIsFollowingObject={setIsFollowingObject}
									brandIds={brandIds ?? []}
									averagePrice={
										products?.data[0]?.averagePrice ?? 0
									}
								/>
								{!!products?.data &&
									!!products?.data.length && (
										<ProductCards
											products={products.data}
											brand={_id}
										/>
									)}
							</View>
						);
					}
				)
			) : isLoading ? (
				<View style={{ marginTop: 0 }}>
					<BrandScreenLoader isMobileView={!isDesktop} />
					<BrandScreenLoader isMobileView={!isDesktop} />
					<BrandScreenLoader isMobileView={!isDesktop} />
				</View>
			) : (
				!handleBrandResult().length && (
					<NotAvailableMessage message={t('No Result Found!')} />
				)
			)}
			{handleBrandResult().length < totalBrands && (
				<>
					<View style={styles.loadMoreContainer}>
						{
							<LoadMore
								onClick={() => {
									setBrandLoading(true);
									handleRefetch();
								}}
								loading={handleNetwork() === 2 || brandLoading}
							/>
						}
					</View>
				</>
			)}
		</DesktopTemplate>
	);
};

export default BrandsScreen;

const styles = StyleSheet.create({
	companyProductRow: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	companyProductColumn: {
		flexDirection: 'column',
		alignItems: 'center',
	},
	loadMoreContainer: { paddingBottom: 50 },
});

function styleCompanyProductCardLayout(isDesktop: boolean) {
	return isDesktop ? styles.companyProductRow : styles.companyProductColumn;
}

function styleCompanyProductRow() {
	return { marginBottom: getResponsiveStyle(40) };
}
