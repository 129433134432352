import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import { v4 as uuidv4 } from 'uuid';
import { socket } from '../../../../../App';
import useCreateLiveEventMessage from '../../../../api/chat/useCreateLiveEventMessage';
import { useAppSelector } from '../../../../redux/store';
import ChatInput from './ChatInput';
import ContactMessage from './ContactMessage';
import UserMessage from './UserMessage';
import { db, storage } from '../../../../services/firebase';
import {
	collection,
	query,
	where,
	onSnapshot,
	addDoc,
	Timestamp,
} from 'firebase/firestore';
import AsyncStorage from '@react-native-community/async-storage';

export type LiveEventMessage = {
	message: any;
	user: TGetAllUsersData;
	roomMessage: boolean;
	createdAt: {
		seconds: number;
		nanoseconds: number;
	};
};
const EventChatBox = ({
	expandHeight,
	isMobileView,
	messages,
	handleTypingFocusIn,
	handleChange,
	eventDetails,
}: {
	expandHeight?: boolean;
	isMobileView?: boolean;
	messages: LiveEventMessage[];
	eventDetails?: TGetAllEventCommon;
	handleTypingFocusIn: () => void;
	handleChange: () => void;
}) => {
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const [anonymousUser, setAnonymousUser] = useState({});

	const { executeCreateLiveEventMessageMutation } =
		useCreateLiveEventMessage();

	// const [messages, setMessages] = useState<LiveEventMessage[]>([]);

	// useEffect(() => {
	// 	socket.on('liveEventMessage', data => {
	// 		setMessages(prev => [...prev, data]);
	// 	});
	// }, []);

	useEffect(() => {
		if (!isUserLoggedIn) {
			AsyncStorage.getItem('vId').then(data => {
				if (data) {
					setAnonymousUser({
						_id: data,
					});
				}
			});
		}
	}, []);

	const handleMessageSend = async (message: any) => {
		// socket.emit('sendLiveEventMessage', {
		// 	message,
		// 	user: loggedInUserDetails,
		// });
		const anonymousUser = {} as any;
		if (!isUserLoggedIn) {
			let anonymousUserId;
			const storedAnonymousId = await AsyncStorage.getItem('vId');
			if (!storedAnonymousId) {
				anonymousUserId = uuidv4();
				await AsyncStorage.setItem('vId', anonymousUserId);
			}
			anonymousUser._id = anonymousUserId || storedAnonymousId;
			anonymousUser.username = await AsyncStorage.getItem(
				'anonymousUsername'
			);
			setAnonymousUser(anonymousUser);
		}
		await addDoc(
			collection(db, 'messages', eventDetails?._id, 'LiveChat'),
			{
				message,
				user: loggedInUserDetails || anonymousUser,
				roomMessage: true,
				createdAt: Timestamp.fromDate(new Date()),
			}
		);
		if (isUserLoggedIn)
			executeCreateLiveEventMessageMutation({
				eventId: eventDetails?._id,
				message: message?.message,
				sender: loggedInUserDetails?._id,
			});
	};

	const scrollViewRef: any = useRef();

	return (
		<View
			style={[
				styles.flex1,
				{ marginBottom: +`${isMobileView ? 15 : 0}` },
			]}
		>
			<ScrollView
				style={[
					styles.container,
					expandHeight && {
						height: `${isMobileView ? '68vh' : '70vh'}`,
					},
				]}
				showsHorizontalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() =>
					scrollViewRef?.current?.scrollToEnd({ animated: true })
				}
			>
				{messages.map((message: any, index) => (
					<View key={index}>
						{message?.user?._id ===
						(loggedInUserDetails?._id || anonymousUser?._id) ? (
							<UserMessage
								user={message?.user}
								message={message.message}
								roomMessage={message.roomMessage}
							/>
						) : (
							<ContactMessage
								user={message?.user}
								message={message.message}
							/>
						)}
					</View>
				))}
			</ScrollView>
			{!eventDetails?.eventStreamTimeline?.endedAt && (
				<ChatInput
					handleChange={handleChange}
					handleTypingFocusIn={handleTypingFocusIn}
					handleTypingFocusOut={() => null}
					onMessageSend={handleMessageSend}
				/>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		marginBottom: 15,
	},
	container: {
		// borderWidth: 2,
		// borderColor: palettes.grey[4],
		// height: 520,
		width: '100%',
		overflow: 'scroll',
		paddingTop: '15px',
	},
});

export default EventChatBox;
