import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	// ActivityIndicator,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'rn-css';
import { socket } from '../../../../App';
import { useGetConversations } from '../../../api/chat';
import useGetUnreadMessages from '../../../api/chat/useGetUnreadMessages';
import useSearchUser from '../../../api/chat/useSearchUser';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { setContactsGlobal, setUnReadMessages } from '../../../redux/chatSlice';
import { useAppSelector } from '../../../redux/store';
import {
	filterFoundUsers,
	getUniquesContacts,
	getUnreadMessages,
	handleSortSelectClick,
} from '../../../utils/chatUtil';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Input from '../../common/Input';
import ContactCardSkeleton from '../../common/skeleton-loaders/chat-loader/ContactCardSkeleton';
import ContactCard from './sub-component/ContacCard';

const StarChatLink = styled.Text<{ primary: string[] }>`
	cursor: pointer;
	border-bottom: 1px solid transparent;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		padding-bottom: 7px;
		border-bottom: 1px solid ${({ primary }) => primary?.[0]};
	}
`;
const Contacts = ({ userId }: { userId: string | undefined }) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [contacts, setContacts] = useState<ChatContactProps[]>([]);
	const [searchResult, setSearchResult] = useState<[]>([]);
	const [allUnreadMessages, setAllUnreadMessages] = useState<
		UnreadMessages[]
	>([]);
	const [modalState, setModalState] = useState(false);
	const [newChatSelect, setNewChatSelect] = useState(false);
	const [refetchContact, setRefetchContact] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const primary = usePrimaryColors();
	// const loggedInUserDetails = useAppSelector(
	// 	state => state.auth.loggedInUserDetails
	// );

	const chat = useAppSelector(state => state.chat.chat);

	const { executeConversationsMutation, loading, data } =
		useGetConversations();

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const { executeSearchQuery, data: userData } = useSearchUser();

	const { executeUnreadMessagesQuery, data: unreadMessagesData } =
		useGetUnreadMessages();

	const onPressCreateEvent = () => {
		setModalState(!modalState);
	};

	useEffect(() => {
		socket.on('sendMessage', message => {
			const unreadMessage = {
				conversationId: message?.contacts?.conversationId,
			} as UnreadMessages;
			setAllUnreadMessages(prev => {
				dispatch(setUnReadMessages([...prev, unreadMessage]));
				return [...prev, unreadMessage];
			});
		});
		executeUnreadMessagesQuery({
			recipientId: loggedInUserDetails?._id as string,
		});
	}, []);

	useEffect(() => {
		const unreadMessages = unreadMessagesData?.getUnreadMessages;
		if (unreadMessages?.success) {
			setAllUnreadMessages(unreadMessages.data);
		}
	}, [unreadMessagesData]);

	const findUser = (searchTerm: string) => {
		if (searchTerm.length) {
			executeSearchQuery({
				searchTerm,
				typeOfAccount: loggedInUserDetails?.typeOfAccount as string,
			});
		}
	};

	const onUserSelect = (conversationId: string) => {
		const messages = getUnreadMessages(
			allUnreadMessages,
			conversationId,
			true
		);
		if (conversationId) {
			setAllUnreadMessages(messages);
			dispatch(setUnReadMessages(messages));
		}
		setRedirect(true);
	};

	useEffect(() => {
		setSearchResult([]);
		executeConversationsMutation({
			userId,
		});
	}, [userId, modalState, refetchContact]);

	useEffect(() => {
		if (modalState) {
			const sortedContact = handleSortSelectClick(
				data?.getAllConversations?.data,
				chat.contactPerson?._id,
				userId
			);
			if (sortedContact?.length) {
				setContacts(sortedContact);
			}
		}
		if (!chat.conversationId && chat.contactPerson?._id) {
			setNewChatSelect(true);
			executeConversationsMutation({
				userId,
			});
			setTimeout(() => {
				setModalState(false);
				setNewChatSelect(false);
			}, 1500);
		}
		setRedirect(false);
	}, [chat.contactPerson?._id]);

	useEffect(() => {
		setNewChatSelect(true);
		setTimeout(() => {
			setModalState(false);
			setNewChatSelect(false);
			setRedirect(false);
		}, 1500);
	}, [redirect]);

	useEffect(() => {
		if (data?.getAllConversations?.data.length) {
			setContacts(
				getUniquesContacts(data?.getAllConversations?.data, userId)
			);
			dispatch(
				setContactsGlobal(
					getUniquesContacts(data?.getAllConversations?.data, userId)
				)
			);
		}
	}, [data?.getAllConversations?.data]);

	useEffect(() => {
		const users = userData?.getUsers?.data?.map((user: TODO) => {
			return {
				conversationId: null,
				contactPerson: user,
			};
		});

		if (users) {
			setSearchResult(users);
		}
	}, [userData?.getUsers?.data]);

	return (
		<View
			style={[
				styles.container,
				isLessThanDesktopBase && { paddingLeft: 20 },
			]}
		>
			<View style={styles.chatLabel}>
				<Text style={styles.chatHeader}>
					{modalState ? t('Start New Chat') : t('Chats')}
				</Text>
				<StarChatLink
					onPress={onPressCreateEvent}
					style={[styles.createEvent, { color: primary?.[0] }]}
					primary={primary}
				>
					{`${modalState ? t('Close') : '+ ' + t('Start New Chat')}`}
				</StarChatLink>
			</View>
			{modalState && (
				<Input
					onChangeText={findUser}
					placeholder={t('Type a name or multiple names')}
				/>
			)}
			{/* {loading ||
				(newChatSelect && (
					<ActivityIndicator
						size="small"
						style={{
							margin: 20,
						}}
					/>
				))} */}
			{/* <ModalComponent
				type="modal"
				header="Start New Chat"
				openState={modalState}
				onClose={onPressCreateEvent}
			>
				<CreateConversation />
			</ModalComponent> */}
			<ScrollView
				contentContainerStyle={{ marginTop: 10 }}
				showsHorizontalScrollIndicator={false}
			>
				{!modalState ? (
					<>
						{!loading
							? contacts.map((contact: TODO, i: number) => (
									<ContactCard
										key={i}
										{...contact}
										allUnreadMessages={allUnreadMessages}
										onUserSelect={onUserSelect}
										refetch={() =>
											setRefetchContact(!refetchContact)
										}
									/>
							  ))
							: [0, 1, 2, 3, 4].map(() => (
									<ContactCardSkeleton />
							  ))}
					</>
				) : (
					filterFoundUsers(
						data?.getAllConversations?.data,
						searchResult,
						userId
					).map((contact, i) => (
						<ContactCard
							key={i}
							{...contact}
							allUnreadMessages={[]}
							onUserSelect={onUserSelect}
							isModalState={true}
						/>
					))
				)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// width: '20%',
		flex: 2,
		borderRightWidth: 1,
		borderColor: palettes.grey[4],
		paddingLeft: 20,
		paddingRight: 20,
	},
	createEvent: {
		alignItems: 'center',
		paddingBottom: 1,
		textAlign: 'right',
		fontSize: getResponsiveStyle(12, 'font'),
	},
	chatLabel: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	chatHeader: {
		fontSize: getResponsiveStyle(20, 'font'),
		lineHeight: getResponsiveStyle(14, 'spacing'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
});

export default Contacts;
