import getSymbolFromCurrency from 'currency-symbol-map';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setEventProduct } from '../../../redux/eventProductDetail';
import { transformToPopulated } from '../../../utils/productCategories';
import { twoDp } from '../../../utils/twoDP';
import ModalComponent from '../../common/modal/ModalComponent';
import ProductView from '../../common/templates/ProductView';
import Button from '../../common/button/Button';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';

export type DesktopHighLightProductProps = {
	eventProduct?: Variant;
	eventData?: TGetAllEventCommon;
};

const DesktopHighLightProduct = ({
	eventProduct,
	eventData,
}: DesktopHighLightProductProps) => {
	// const dispatch = useDispatch();
	// const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	// const [selectedVariant, setSelectedVariant] = useState<PopulatedVariant>();
	// const [modalState, setModalState] = useState(false);
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const { t } = useTranslation();

	const { visitorCartStore } = useAppSelector(state => state.user);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	const { mutateCart, createLoading, isLoadingVisitorAddToCart } =
		useAddToCart({ selectedVariant: eventProduct, event: eventData });

	let inCart = false;
	usersCart.map(cartVariant => {
		if (
			cartVariant.type === 'Product' &&
			cartVariant?.productId?._id === eventProduct?._id
		) {
			if (!cartVariant?.productId?.option?.values?.length) {
				inCart = true;
			} else if (
				cartVariant?.optionValue ===
				eventProduct?.option?.values?.[0]?.name
			) {
				inCart = true;
			}
		}
	});

	const availableVariantCombination: any = () => {
		const variantWithQuantity = Object.values(eventProduct?.option?.variatorValues?.[0]?.variatorValues || {})?.find((data: any) => data?.quantity >= 1)
		return variantWithQuantity
	}

	const isItemOutOfStock = availableVariantCombination() ? availableVariantCombination()?.quantity <= 0 : +eventProduct?.option?.inventory?.quantity <= 0;


	// const isItemOutOfStock = +eventProduct?.option?.inventory?.quantity <= 0;

	const addToCartText = inCart
		? 'In Cart'
		: isItemOutOfStock
			? t('Out of stock')
			: t('Add to cart');

	const buttonVariant = eventProduct?.option?.values?.length
		? eventProduct?.option?.values?.[0]?.name
			? 'primary'
			: 'disabled'
		: 'primary';

	const buttonVariantWithStockQuantity =
		isItemOutOfStock || inCart ? 'disabled' : buttonVariant;

	// const onPressProduct = (variant: Variant, product: Product) => {
	// 	setSelectedVariant(transformToPopulated(variant, product));
	// 	// setVariant(product.variants);
	// 	// setProduct(product);

	// 	setModalState(true);
	// };

	// console.log({ eventProduct });

	return (
		<View style={styles.flex1}>
			<Pressable
				onPress={() => {
					// onPressProduct(variant, product);
					// dispatch(
					// 	setEventProduct({
					// 		desc: '12 Colors Velvet Matte Lipsticks Pencil Natural Waterproof',
					// 		price: 328.85,
					// 	})
					// );
				}}
			>
				<View style={styles.container}>
					<Image
						style={styles.image}
						source={{ uri: eventProduct?.image?.src }}
					/>
					<View style={styles.info}>
						<Text style={styles.text}>
							{eventProduct?.associatedProduct?.name?.substring(
								0,
								30
							)}
						</Text>
						<Text style={styles.price}>
							{getSymbolFromCurrency('eur')}
							{twoDp(availableVariantCombination()?.price || eventProduct?.option?.values?.[0]?.price)}
						</Text>
					</View>
					<View style={{ alignSelf: 'center' }}>
						<Button
							title={addToCartText}
							variant={
								loggedInUserDetails?.typeOfAccount === 'SELLER'
									? 'disabled'
									: buttonVariantWithStockQuantity
							}
							width={100}
							loading={createLoading || isLoadingVisitorAddToCart}
							onPress={mutateCart}
						/>
					</View>
				</View>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	flex1: {
		// flex: 1,
		// marginBottom: 15,
		// width: "40%",
		flexDirection: 'row',
	},
	container: {
		// width: 550,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderRadius: 6,
		backgroundColor: palettes.light[0],
	},
	image: {
		height: 98,
		width: 98,
		marginRight: '12px',
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
	},
	info: {
		justifyContent: 'space-around',
		lineHeight: 16,
		marginRight: 20,
	},
	text: {
		fontSize: 11,
		lineHeight: 16,
		fontStyle: 'normal',
		fontWeight: 'normal',
	},
	price: {
		fontWeight: 'bold',
		color: '#10162',
		fontSize: 13,
		lineHeight: 18,
	},
});

export default DesktopHighLightProduct;
