import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { StyleSheet } from 'react-native';
import useFacebookAuth from '../../../../api/auth/useFacebookAuth';
import useGoogleAuth from '../../../../api/auth/useGoogleAuth';
import { useSetAlert } from '../../../../hooks/useSetAlerts';
// import { setLoggedInUserDetails } from '../../../../redux/authSlice';
import { setGoogleLoginData } from '../../../../redux/createUserSlice';
// import { setSocialAuthToken } from '../../../../redux/socialAuth';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
// import { setPrimaryColors } from '../../../../redux/uiSlice';
import { TUseNavigation } from '../../../../types/exportedTypes';
import { TStyle } from '../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { AppleIcon, FacebookIcon, GoogleIcon } from '../../../common/icons';
import Spacing from '../../../common/spacing/Spacing';
import { SignupCompanyButton } from './SignupCompanyButton';
import { useTranslation } from 'react-i18next';
import { setIsSocialAuth, setPrimaryColors } from '../../../../redux/uiSlice';
import { setLoggedInUserDetails } from '../../../../redux/authSlice';
import {
	FACEBOOK_CLIENT_ID,
	PEOPLE_GOOGLE_API,
	REDIRECT_URI,
} from '../../../../constants/KEYS';
import { handleNavigationToEvent } from '../../../../utils/handleNavigationToEvent';

export const SIGN_UP_BUTTONS = [
	{
		id: 0,
		label: 'Google',
		icon: GoogleIcon,
		fn: (): any => {
			alert('Google button clicked');
		},
	},
	{
		id: 1,
		label: 'Facebook',
		icon: FacebookIcon,
		fn: (): any => {
			alert('Facebook button clicked');
		},
	},
	{
		id: 2,
		label: 'Apple',
		icon: AppleIcon,
		fn: (): any => {
			alert('Apple button clicked');
		},
	},
];

const SignupCompanyButtons = ({
	isCompleteSignUpFromBackend,
}: {
	isCompleteSignUpFromBackend?: boolean;
}) => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { signUpSubscribeEvent } = useAppSelector(state => state.user);

	const [credentials, setCredentials] = useState({});

	// const { token } = useAppSelector(state => state.socialAuth);

	const login = useGoogleLogin({
		onSuccess: async tokenResponse => {
			console.log({ tokenResponse });

			const { data } = await axios({
				url: PEOPLE_GOOGLE_API,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${tokenResponse?.access_token}`,
				},
			});

			const email = data?.emailAddresses?.[0]?.value;

			const username = email?.split('@')?.[0];

			dispatch(
				setGoogleLoginData({
					username,
					email,
					familyName: data?.names?.[0]?.familyName,
					givenName: data?.names?.[0]?.givenName,
					imageUrl: data?.photos?.[0]?.url,
					picture: data?.photos?.[0]?.url,
				})
			);

			executeGoogleAuthMutation({
				token: tokenResponse.access_token,
				isCompleteSignUpFromBackend,
			});
		},
	});

	const { executeGoogleAuthMutation, data: googleAuthData } = useGoogleAuth();
	const { executeFacebookAuthMutation, data: facebookAuthData } =
		useFacebookAuth();

	const googleOnSuccess = (response: any) => {
		const profileObj: TGoogleLoginData = response.profileObj;
		dispatch(
			setGoogleLoginData({
				username: '',
				email: profileObj.email,
				familyName: profileObj.familyName,
				givenName: profileObj.givenName,
				imageUrl: profileObj.imageUrl,
			})
		);
		executeGoogleAuthMutation({
			token: response.tokenId,
			isCompleteSignUpFromBackend,
		});
		// dispatch(setSocialAuthToken(response.tokenId));
		// !!response && navigation.navigate('UserSelect');
	};

	useEffect(() => {
		dispatch(setIsSocialAuth(false));
		const responseData =
			googleAuthData?.googleAuth || facebookAuthData?.facebookAuth;

		if (responseData?.data && !responseData?.data?.newAccount) {
			if (signUpSubscribeEvent) {
				handleNavigationToEvent({
					event: signUpSubscribeEvent,
					navigation,
					loggedInUserDetails: responseData?.data?.user,
					dispatch,
				});
			}
			dispatch(setPrimaryColors(responseData?.data?.user?.typeOfAccount));
			dispatch(
				setLoggedInUserDetails({
					isUserLoggedIn: true,
					...responseData?.data?.user,
				})
			);
		}

		if (responseData?.data?.newAccount) {
			navigation.navigate('UserSelect');
			dispatch(setIsSocialAuth(true));
		}
	}, [googleAuthData, facebookAuthData]);

	const googleOnFailure = (res: any) => {
		setAlert(
			t(
				'Unable to login using Google! Kindly use other methods to login.'
			),
			'danger'
		);
	};
	const facebookCallback = (response: any) => {
		const profileObj: TGoogleLoginData = response;

		console.log({
			response,
		});

		setCredentials(response);

		dispatch(
			setGoogleLoginData({
				username: '',
				email: profileObj.email,
				familyName: profileObj.name.split(' ')[1],
				givenName: profileObj.name.split(' ')[0],
				imageUrl: profileObj.picture?.data?.url as string,
			})
		);
		executeFacebookAuthMutation({
			token: response.accessToken,
			isCompleteSignUpFromBackend,
		});
		// !!response && navigation.navigate('UserSelect');
	};

	useEffect(() => {
		const fetchVideos = async () => {
			// console.log({ credentials });

			try {
				const response = await axios
					.post('https://graph.facebook.com/v3.3/me/live_videos', {
						status: 'LIVE_NOW',
						access_token: credentials?.accessToken,
					})
					.then(response => {
						console.log(
							'Live video started successfully:',
							response.data
						);
					})
					.catch(error => {
						console.error('Error starting live video:', error);
					});

				// console.log(response.data);
			} catch (error) {
				console.error('Error fetching videos:', error);
			}
		};

		fetchVideos();
	}, [credentials]);

	return (
		<Spacing
			withEqualWidth
			spacing={8}
			containerStyles={{ marginBottom: getResponsiveStyle(45) }}
		>
			{/* {SIGN_UP_BUTTONS.map(({ icon, id, label }) => (
					<SignupCompanyButton key={id} icon={icon} label={label} />
				))} */}
			{/* <GoogleLogin
				// clientId="254414208246-jsd2bjkhtsi2j9rel4lg7cl0fb415373.apps.googleusercontent.com"
				clientId="832276712377-n1rhp4rcpd64hleu5tcre83kv2hkgk1a.apps.googleusercontent.com"
				onSuccess={googleOnSuccess}
				onFailure={googleOnFailure}
				render={(renderProps: any) => {
					return (
						<SignupCompanyButton
							key={SIGN_UP_BUTTONS[0].id}
							icon={SIGN_UP_BUTTONS[0].icon}
							label={SIGN_UP_BUTTONS[0].label}
							onClick={renderProps.onClick}
						/>
					);
				}}
			/> */}

			<SignupCompanyButton
				key={SIGN_UP_BUTTONS[0].id}
				icon={SIGN_UP_BUTTONS[0].icon}
				label={SIGN_UP_BUTTONS[0].label}
				onClick={login}
			/>

			{/* <GoogleLogout
					clientId="254414208246-jsd2bjkhtsi2j9rel4lg7cl0fb415373.apps.googleusercontent.com"
					buttonText="Logout"
					onLogoutSuccess={() =>}
				></GoogleLogout> */}

			{/* <SignupCompanyButton
				key={SIGN_UP_BUTTONS[1].id}
				icon={SIGN_UP_BUTTONS[1].icon}
				label={SIGN_UP_BUTTONS[1].label}
				// onClick={renderProps.onClick}
			/> */}

			<FacebookLogin
				// appId="734818670865062"
				appId={FACEBOOK_CLIENT_ID}
				fields="name,email,picture"
				callback={facebookCallback}
				// redirectUri={REDIRECT_URI}
				cookie={true}
				xfbml={true}
				version={'3.2'}
				autoLoad={false}
				isMobile={true}
				disableMobileRedirect={true}
				render={(renderProps: any) => {
					return (
						<SignupCompanyButton
							key={SIGN_UP_BUTTONS[1].id}
							icon={SIGN_UP_BUTTONS[1].icon}
							label={SIGN_UP_BUTTONS[1].label}
							onClick={renderProps.onClick}
						/>
					);
				}}
			/>
		</Spacing>
	);
};

export default SignupCompanyButtons;

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	},
});

function styleContainer(): TStyle {
	return {
		marginRight: getResponsiveStyle(-8),
		marginTop: getResponsiveStyle(40),
	};
}
