import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import {
	AuthTemplate,
	Button,
	LoginFooter,
	LoginForm,
	LoginHeroImage,
	SignupCompanyButtons,
	SignupWithDivider,
	TermsAndConditions,
} from '../components';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TUseNavigation } from '../types/exportedTypes';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useAppDispatch } from '../redux/store';
import {
	setCurrentScreen,
	setPrimaryColors,
	setSignupSidebarValues,
} from '../redux/uiSlice';
import { setTypeOfAccount } from '../redux/createUserSlice';

const LoginScreen = () => {
	const { t } = useTranslation();
	const { isDesktop } = useIsSpecificViewportWidth();
	const { shouldHideScreen } = useHandleUnmount();
	const navigation = useNavigation<TUseNavigation>();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setCurrentScreen('/login'));
	}, []);

	const handlePressForgetPassword = () => {
		navigation.navigate('ForgotPassword');
	};

	const onPressSignup = () => {
		// navigation.navigate('UserSelect');
		dispatch(setTypeOfAccount('CONSUMER'));
		dispatch(setPrimaryColors('CONSUMER'));
		dispatch(setSignupSidebarValues('CONSUMER'));
		navigation.navigate('PersonalInfo');
	};

	return shouldHideScreen ? null : (
		<View style={isDesktop && styles.container}>
			<LoginHeroImage />
			<AuthTemplate
				heading={t('Welcome Back!')}
				subText={t(
					'Create your account in one click to enjoy all the benefits'
				)}
				contentStyles={styleMainContent(isDesktop)}
				authType={'login'}
				scrollItems={[]}
				hasGoBack={false}
			>
				{/* <View style={{ marginTop: getResponsiveStyle(40) }}>
					<SignupCompanyButtons />
				</View> */}
				{/* <SignupWithDivider
					containerStyles={{ marginBottom: getResponsiveStyle(10) }}
					text={t('Or connect with')}
				/> */}
				<Button
					title={t('I create my account')}
					variant="outline"
					size="lg"
					outerContainerProps={{
						style: { marginBottom: getResponsiveStyle(30) },
					}}
					onPress={() => onPressSignup()}
				/>
				<SignupWithDivider
					containerStyles={{ marginBottom: getResponsiveStyle(10) }}
					text={t('Or create your account')}
				/>
				<View style={styleLoginFormContainer()}>
					<LoginForm
						handlePressForgetPassword={handlePressForgetPassword}
					/>
				</View>

				{/* <SignupWithDivider text={t('Or create your account')} /> */}

				{/* <Button
					title={t('Sign Up')}
					variant="outline"
					size="lg"
					outerContainerProps={{
						style: styleSignUpButtonContainer(),
					}}
					onPress={() => onPressSignup()}
				/> */}

				{isDesktop && <LoginFooter />}
				{/* {isDesktop && <TermsAndConditions />} */}
			</AuthTemplate>
		</View>
	);
};

function styleMainContent(isDesktop: boolean): TStyle {
	return {
		marginTop: getResponsiveStyle(isDesktop ? 0 : 10),
	};
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		minWidth: '100vw',
		height: '100vh',
	},
});

export default LoginScreen;

function styleSignUpButtonContainer() {
	return {
		marginBottom: getResponsiveStyle(92),
	};
}

function styleLoginFormContainer() {
	return {
		marginTop: getResponsiveStyle(10),
		marginBottom: getResponsiveStyle(40),
	} as TStyle;
}
