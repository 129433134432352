import {
	ParamListBase,
	RouteConfig,
	StackNavigationState,
} from '@react-navigation/native';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { NativeStackNavigationEventMap } from '@react-navigation/native-stack/lib/typescript/src/types';
import { SettingCatchmentArea } from '../app/components';
import {
	AvailabilityScreen,
	BrandsScreen,
	CreateEventScreen,
	CreditCardInformationScreen,
	EventCompleteScreen,
	EventDescriptionScreen,
	EventDetailScreen,
	EventDurationScreen,
	EventHostScreen,
	EventInviteScreen,
	EventLocationScreen,
	EventCatchmentArea,
	EventProductScreen,
	EventsScreen,
	EventUploadMediaScreen,
	HomeScreen,
	LiveEventScreen,
	LoginScreen,
	NetworkScreen,
	PersonalInfoScreen,
	ProductsUploadScreen,
	ProfileScreen,
	SellerAgreementScreen,
	SignupCompleteScreen,
	UserSelectScreen,
	WelcomeScreen,
	YourInformationScreen,
	SaveAsScreen,
	ChatScreen,
	ForgotPassword,
	ResetPassword,
	BrandDetailsScreen,
	UserProfileScreen,
	CheckoutScreen,
	CheckoutAddressScreen,
	PurchaseCompleteScreen,
	OrderDetails,
	VerifyEmail,
} from '../app/screens';

import { TRootStackParamList } from '../app/types/exportedTypes';

type TNavigationScreensConfig = RouteConfig<
	TRootStackParamList,
	keyof TRootStackParamList,
	StackNavigationState<ParamListBase>,
	NativeStackNavigationOptions,
	NativeStackNavigationEventMap
> & {
	protectedRoute?: boolean;
};

export const ScreenConfig: TNavigationScreensConfig[] = [
	{
		name: 'Events',
		options: { headerShown: false },
		component: EventsScreen,
	},
	{
		name: 'Index',
		component: HomeScreen,
		options: { headerShown: false },
	},
	{
		name: 'Home',
		component: HomeScreen,
		options: { headerShown: false },
	},
	{
		name: 'Welcome',
		options: { headerShown: false },
		component: WelcomeScreen,
	},
	{
		name: 'EventsAuthorized',
		options: { headerShown: false },
		component: EventsScreen,
		protectedRoute: false,
	},
	{
		name: 'Login',
		options: { headerShown: false },
		component: LoginScreen,
		protectedRoute: false,
	},
	{
		name: 'ForgotPassword',
		options: { headerShown: false },
		component: ForgotPassword,
		protectedRoute: false,
	},
	{
		name: 'Reset',
		options: { headerShown: false },
		component: ResetPassword,
		protectedRoute: false,
	},
	{
		name: 'UserSelect',
		options: { headerShown: false },
		component: UserSelectScreen,
		protectedRoute: false,
	},
	{
		name: 'PersonalInfo',
		options: { headerShown: false },
		component: PersonalInfoScreen,
		protectedRoute: false,
	},
	{
		name: 'SellerAgreement',
		options: { headerShown: false },
		component: SellerAgreementScreen,
		protectedRoute: false,
	},
	{
		name: 'YourInformation',
		options: { headerShown: false },
		component: YourInformationScreen,
		protectedRoute: false,
	},
	{
		name: 'CreditCardInformation',
		options: { headerShown: false },
		component: CreditCardInformationScreen,
		protectedRoute: false,
	},
	{
		name: 'OrderDetails',
		options: { headerShown: false },
		component: OrderDetails,
		protectedRoute: true,
	},
	{
		name: 'VerifyEmail',
		options: { headerShown: false },
		component: VerifyEmail,
		protectedRoute: false,
	},
	{
		name: 'SettingCatchmentArea',
		options: { headerShown: false },
		component: SettingCatchmentArea,
		protectedRoute: false,
	},
	{
		name: 'Availability',
		options: { headerShown: false },
		component: AvailabilityScreen,
		protectedRoute: false,
	},
	{
		name: 'ProductsUpload',
		options: { headerShown: false },
		component: ProductsUploadScreen,
		protectedRoute: false,
	},
	{
		name: 'SignupComplete',
		options: { headerShown: false },
		component: SignupCompleteScreen,
		protectedRoute: false,
	},
	{
		name: 'SaveAs',
		options: { headerShown: false },
		component: SaveAsScreen,
	},
	// {
	// 	name: 'Events',
	// 	options: { headerShown: false },
	// 	component: EventsScreen,
	// },
	{
		name: 'LiveEvent',
		options: { headerShown: false },
		component: LiveEventScreen,
		protectedRoute: true,
	},
	{
		name: 'LiveEvent',
		options: { headerShown: false },
		component: LiveEventScreen,
		protectedRoute: false,
	},
	{
		name: 'CreateEvent',
		options: { headerShown: false },
		component: CreateEventScreen,
	},
	{
		name: 'EventDetail',
		options: { headerShown: false },
		component: EventDetailScreen,
	},
	{
		name: 'EventDuration',
		options: { headerShown: false },
		component: EventDurationScreen,
	},
	{
		name: 'EventLocation',
		options: { headerShown: false },
		component: EventLocationScreen,
	},
	{
		name: 'CatchmentArea',
		options: { headerShown: false },
		component: EventCatchmentArea,
	},
	{
		name: 'EventUpload',
		options: { headerShown: false },
		component: EventUploadMediaScreen,
	},
	{
		name: 'EventInvite',
		options: { headerShown: false },
		component: EventInviteScreen,
	},
	{
		name: 'EventHost',
		options: { headerShown: false },
		component: EventHostScreen,
	},
	{
		name: 'EventProduct',
		options: { headerShown: false },
		component: EventProductScreen,
	},

	{
		name: 'EventComplete',
		options: { headerShown: false },
		component: EventCompleteScreen,
	},
	{
		name: 'EventDescription',
		options: { headerShown: false },
		component: EventDescriptionScreen,
		protectedRoute: false,
	},
	{
		name: 'EventDescription',
		options: { headerShown: false },
		component: EventDescriptionScreen,
		protectedRoute: true,
	},
	{
		name: 'Network',
		options: { headerShown: false },
		component: NetworkScreen,
	},
	{
		name: 'Brands',
		options: { headerShown: false },
		component: BrandsScreen,
	},
	{
		name: 'BrandDetails',
		options: { headerShown: false },
		component: BrandDetailsScreen,
	},
	{
		name: 'Profile',
		options: { headerShown: false },
		component: ProfileScreen,
	},
	{
		name: 'UserProfile',
		options: { headerShown: false },
		component: UserProfileScreen,
	},
	{
		name: 'Chat',
		options: { headerShown: false },
		component: ChatScreen,
		protectedRoute: true,
	},
	{
		name: 'Checkout',
		options: { headerShown: false },
		component: CheckoutScreen,
		protectedRoute: true,
	},
	{
		name: 'Checkout',
		options: { headerShown: false },
		component: CheckoutScreen,
		protectedRoute: false,
	},
	{
		name: 'CheckoutAddress',
		options: { headerShown: false },
		component: CheckoutAddressScreen,
		protectedRoute: true,
	},
	{
		name: 'CheckoutAddress',
		options: { headerShown: false },
		component: CheckoutAddressScreen,
		protectedRoute: false,
	},
	{
		name: 'PurchaseComplete',
		options: { headerShown: false },
		component: PurchaseCompleteScreen,
		protectedRoute: true,
	},
	{
		name: 'PurchaseComplete',
		options: { headerShown: false },
		component: PurchaseCompleteScreen,
		protectedRoute: false,
	},
];
