import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Checkbox, Input } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { setIsSaveAddressCard } from '../../../redux/getCardsSlice';
import { setPayingAddressDetails } from '../../../redux/oneStopCheckoutSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import countryNamesWithCodes from '../../../utils/countryNamesWithCodes';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Dropdown from '../../common/dropdown/Dropdown';

export type TAddressBookInitialValues = Yup.InferType<typeof schema>;

const schema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const visitorSchema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const initialValues: Omit<TAddressBook, 'createdBy' | '_id'> = {
	name: '',
	firstName: '',
	lastName: '',
	email: '',
	city: '',
	countery: 'fr',
	address: '',
	address2: '',
	zipCode: '',
	phoneNumber: '',
};

type Props = {
	onSubmit: (values: Omit<TAddressBook, 'createdBy' | '_id'>) => void;
	loading: boolean;
	isEdit: boolean;
	addressBookToEditDetails?: Omit<TAddressBook, 'createdBy'>;
	onCheckout?: boolean;
	setName?: (val: string) => void;
	setAddress?: (val: string) => void;
	setCity?: (val: string) => void;
	setZipCode?: (val: string) => void;
	setCountry?: (val: string) => void;
	isFullWidth?: boolean;
	isAddAddress?: boolean;
	isNotSaveForLater?: boolean;
	phoneNumber?: string;
};

const AddAddressForm = ({
	onSubmit,
	loading,
	isEdit,
	addressBookToEditDetails,
	onCheckout,
	isFullWidth,
	isAddAddress,
	isNotSaveForLater,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [btnValue, setBtnValue] = useState<'Save' | 'Saved'>('Save');
	const [isSaveAddress, setIsSaveAddress] = useState(false);
	const navigation = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const { setAlert } = useSetAlert();
	const handleSaveAddressOnCheckout = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		const country = getCountryLabelFromValue(values.countery);
		if (country !== 'France' && country !== 'Belgium') {
			setAlert(
				t(
					'Only shipping addresses in France and Belgium can be used to make purchases'
				),
				'danger'
			);
		} else {
			await dispatch(
				setPayingAddressDetails({
					address: values.address,
					city: values.city,
					countery: values.countery,
					name: `${values?.firstName} ${values?.lastName}`,
					zipCode: values.zipCode,
					phoneNumber: values.phoneNumber,
					email: values?.email,
					firstName: values?.firstName,
					lastName: values?.lastName,
				})
			);
			await dispatch(setIsSaveAddressCard(isSaveAddress));
			if (!isAddAddress) navigation.navigate('Checkout');
		}
		// setBtnValue('Saved');
	};
	const handleSubmit = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		onSubmit(values);
		onCheckout && handleSaveAddressOnCheckout(values);
	};

	const onPressBack = () => {
		navigation.canGoBack()
			? navigation.goBack()
			: navigation.navigate('Home');
	};

	const marginBottom = isLessThanDesktopBase ? 20 : 10;

	return (
		<Formik
			enableReinitialize
			initialValues={
				isEdit && addressBookToEditDetails
					? addressBookToEditDetails
					: initialValues
			}
			validationSchema={isUserLoggedIn ? schema : visitorSchema}
			onSubmit={values => {
				handleSubmit(values);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
				setFieldValue,
				isValid,
				dirty,
			}) => (
				<>
					<View
						style={[
							{ zIndex: 5000, width: '100%' },
							!isLessThanDesktopBase && {
								width: isFullWidth ? '100%' : '70%',
							},
						]}
					>
						{/* {isUserLoggedIn ? (
							<Input
								value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('name')}
								onBlur={handleBlur('name')}
								label={t('Name')}
								error={touched.name ? errors.name : ''}
							/>
						) : ( */}
						<>
							<Input
								// value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('firstName')}
								onBlur={handleBlur('firstName')}
								label={t('First Name')}
								error={
									touched.firstName ? errors.firstName : ''
								}
							/>
							<Input
								// value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('lastName')}
								onBlur={handleBlur('lastName')}
								label={t('Last Name')}
								error={touched.lastName ? errors.lastName : ''}
							/>
						</>

						{!isUserLoggedIn && (
							<Input
								// value={values.email}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('email')}
								onBlur={handleBlur('email')}
								label={t('Email')}
								error={touched.email ? errors.email : ''}
							/>
						)}

						<Input
							value={values.address}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address')}
							onBlur={handleBlur('address')}
							label={t('Address')}
							error={touched.address ? errors.address : ''}
						/>

						<Input
							value={values.address2}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address2')}
							onBlur={handleBlur('address2')}
							// label={t('')}
							error={touched.address ? errors.address : ''}
						/>
						{onCheckout ? (
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<View style={{ flex: 1, marginRight: 10 }}>
									<Input
										value={values.city}
										containerStyles={{
											marginBottom:
												getResponsiveStyle(10),
										}}
										onChangeText={handleChange('city')}
										onBlur={handleBlur('city')}
										label={t('City')}
										error={touched.city ? errors.city : ''}
										// placeholder="Enter account name"
									/>
								</View>

								<View style={{ flex: 1 }}>
									<Input
										value={values.zipCode}
										containerStyles={{
											marginBottom:
												getResponsiveStyle(
													marginBottom
												),
										}}
										onChangeText={handleChange('zipCode')}
										onBlur={handleBlur('zipCode')}
										label={t('Zip Code')}
										error={
											touched.zipCode
												? errors.zipCode
												: ''
										} // placeholder="Enter account name"
									/>
								</View>
							</View>
						) : (
							<>
								<Input
									value={values.city}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('city')}
									onBlur={handleBlur('city')}
									label={t('City')}
									error={touched.city ? errors.city : ''}

									// placeholder="Enter account name"
								/>

								<Input
									value={values.zipCode}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('zipCode')}
									onBlur={handleBlur('zipCode')}
									label={t('Zip Code')}
									error={
										touched.zipCode ? errors.zipCode : ''
									}
									// placeholder="Enter account name"
								/>
							</>
						)}

						{/* <Label
							error={
								touched.country || touched.country
									? errors.country
									: ''
							}
							label="Country"
						/> */}
						<Dropdown
							isSearchable
							dropdownPosition="top"
							options={countryNamesWithCodes}
							labelProps={{
								label: t('Country'),
							}}
							onChangeValue={itemSelected => {
								setFieldValue('countery', itemSelected.value);
							}}
							values={{
								label: getCountryLabelFromValue(
									values.countery ?? ''
								),
								value: values.countery ?? '',
							}}
							error={
								touched.countery || touched.countery
									? errors.countery
									: ''
							}
						/>
						<Input
							value={values.phoneNumber}
							style={[styles.input]}
							containerStyles={{
								marginTop: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('phoneNumber')}
							onBlur={handleBlur('phoneNumber')}
							label={t('Phone Number')}
							error={
								touched.phoneNumber ? errors.phoneNumber : ''
							}
						/>
						{/* <Picker
							selectedValue={values.country}
							onValueChange={itemValue =>
								setFieldValue('country', itemValue)
							}
							// containerStyles={{
							// 	marginBottom: getResponsiveStyle(35),

							// 	flexBasis: '50%',
							// }}
							style={[
								styles.dropdownContainer,
								{
									marginBottom: getResponsiveStyle(35),
									flexBasis: '50%',
								},
								Platform.OS === 'web' &&
								!touched.country &&
								!errors.country
									? styleFocusOutline(primaryColors)
									: styleErrorOutline(),

								// styleInput(decoration),
								!!errors.country &&
									touched.country &&
									styles.inputError,
							]}
							dropdownIconColor={palettes.grey[1]}
							onBlur={handleBlur('country')}
							itemStyle={styles.dropdownItem}
						>
							<Picker.Item
								label="Country"
								value=""
								enabled={false}
							/>
							{countryNamesWithCodes.map(country => (
								<Picker.Item
									label={country.label}
									value={country.label}
								/>
							))}
						</Picker> */}
						{!isNotSaveForLater && isUserLoggedIn && (
							<Checkbox
								// hintText={getAgreementStatement()}
								containerStyles={{
									marginTop: getResponsiveStyle(
										14,
										'spacing'
									),
								}}
								hintText={`${t('Save info for later')}`}
								onChange={value =>
									setIsSaveAddress(!isSaveAddress)
								}
								value={isSaveAddress}
							/>
						)}
					</View>

					<View style={styles.buttonContainer}>
						{/* {!isLessThanDesktopBase && (
							<Button
								title={t('Back')}
								variant="grey-outline"
								outerContainerProps={{
									style: styleLoginButtonOuterContainer(),
								}}
								width={getResponsiveStyle(40, 'spacing')}
								size="lg"
								loading={!isAddAddress && loading}
								onPress={onPressBack}
							/>
						)} */}

						<Button
							title={isAddAddress ? t('Save') : t('Next')}
							variant={
								isValid && !!values.countery
									? 'primary'
									: 'disabled'
							}
							outerContainerProps={{
								style: [
									styleLoginButtonOuterContainer(),
									{
										width: isLessThanDesktopBase
											? '100%'
											: '35%',
									},
								],
							}}
							// width={getResponsiveStyle(
							// 	isLessThanDesktopBase ? 155 : 70,
							// 	'spacing'
							// )}
							size="lg"
							loading={loading}
							onPress={handleSubmit}
						/>
					</View>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '100%',
		height: '50px',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		marginBottom: getResponsiveStyle(35),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
});

export default AddAddressForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(36),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
