import { View, Text, StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import { palettes } from '../../../../config';
import Button from '../../button/Button';
import LockIcon from '../../icons/LockIcon';
import CartProductCard from './CartProductCard';
import { useNavigation, useRoute } from '@react-navigation/native';
import { TUseNavigation } from '../../../../types/exportedTypes';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-web-hover';
import getSymbolFromCurrency from 'currency-symbol-map';
import { twoDp } from '../../../../utils/twoDP';
import {
	setBuyingFromEvent,
	setGlobalCartOpen,
} from '../../../../redux/userSlice';
import { useSetAlert } from '../../../../hooks/useSetAlerts';

interface Props {
	onCheckout: boolean;
	setModalState: (e?: boolean) => void;
}
const CartList = ({ onCheckout, setModalState }: Props) => {
	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [isVariantCombinationAvailable, setIsVariantCombinationAvailable] = useState(true)

	const route = useRoute();

	let Total = 0;

	usersCart.map(product => {
		Total +=
			product.type === 'Product'
				? product?.price * product.quantity
				: product?.starterkitId?.price * product.quantity;
	});
	const navigation = useNavigation<TUseNavigation>();
	const { setAlert } = useSetAlert();
	const country = useAppSelector(
		state =>
			state.auth.loggedInUserDetails?.personalInformation?.address
				?.countryLabel
	);

	const onClickCheckout = () => {
		// console.log(country);
		// if (country !== 'France' && country !== 'Belgium') {
		// 	setModalState();
		// 	setAlert(
		// 		t('Only people in France and Belgium can make purchases'),
		// 		'danger'
		// 	);
		// } else {
		dispatch(setGlobalCartOpen(false));
		navigation.navigate('CheckoutAddress');
		(route.name === 'LiveEvent' || route.name === 'EventDescription') &&
			dispatch(setBuyingFromEvent(route?.params?.eventId));
		// }
	};

	const checkVariantCombinationIsAvailable = (isAvailable: boolean) => {
		if (!isAvailable) {
			// dispatch(setGlobalCartOpen(false));
			// setModalState(false)
		}
		setIsVariantCombinationAvailable(isAvailable)
	}
	if (!usersCart) {
		return <Text>{t('You have no item in your cart')}</Text>;
	} else {
		return (
			<View style={[styles.container, !onCheckout && { height: '75vh' }]}>
				<ScrollView
					style={
						!onCheckout
							? styles.cartScroll
							: styles.cartScrollOnCheckout
					}
					showsVerticalScrollIndicator={false}
				>
					{usersCart.length > 0 &&
						usersCart.map((product: CartItem, index: number) => (
							<CartProductCard
								product={product}
								key={index}
								index={index}
								checkVariantCombinationIsAvailable={checkVariantCombinationIsAvailable}
							/>
						))}
				</ScrollView>
				{!onCheckout && (
					<>
						{usersCart.length > 0 && (
							<>
								<View style={styles.cartTotal}>
									<Text style={styles.subtotal}>
										{t('Subtotal')}
									</Text>
									<Text style={styles.total}>
										{getSymbolFromCurrency('eur')}
										{twoDp(Total)}
									</Text>
								</View>

								<Button
									title={`${t('Checkout')}`}
									variant={isVariantCombinationAvailable ? "primary" : "disabled"}
									outerContainerProps={{
										style: {
											marginTop: getResponsiveStyle(30),
										},
									}}
									size="lg"
									// onPress={handleSubmit}
									onPress={() => onClickCheckout()}
								/>
								<Button
									title={`${t('Continue Shopping')}`}
									variant="outline"
									size="lg"
									// onPress={handleSubmit}
									onPress={() => {
										dispatch(setGlobalCartOpen(false));
									}}
								/>
								<View style={styles.secureTextContainer}>
									<LockIcon />
									<Text style={styles.secureText}>
										{t(
											'Secure checkout and payment methods'
										)}
									</Text>
								</View>
							</>
						)}
					</>
				)}
			</View>
		);
	}
};

const styles = StyleSheet.create({
	container: {
		paddingTop: getResponsiveStyle(24),
		// paddingBottom: getResponsiveStyle(34),
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	cartScroll: {
		maxHeight: getResponsiveStyle(300),
		marginBottom: getResponsiveStyle(50),
	},
	cartScrollOnCheckout: {
		// minHeight: getResponsiveStyle(300),
		height: '63vh',
	},
	secureText: {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		fontWeight: '400',
	},
	cartTotal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		// marginTop: getResponsiveStyle(100),
	},
	total: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	subtotal: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		color: palettes.dark[2],
		fontWeight: 'bold',
	},
	secureTextContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(9.78),
		marginTop: getResponsiveStyle(14),
	},
});

export default CartList;
