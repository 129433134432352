import { gql } from '@apollo/client';
import {
	GET_ALL_LIVE_EVENTS_FRAGMENT,
	GET_ALL_PHYSICAL_EVENTS_FRAGMENT,
} from '../events/queries';
import {
	BRAND_FRAGMENT,
	BRAND_PRODUCTS,
	BRAND_PRODUCTS_WITH_TOTAL,
} from './fragments';

export const GET_ALL_BRANDS = gql`
	${BRAND_PRODUCTS_WITH_TOTAL}
	query getAllBrands(
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
		$productCurrent: Int
		$productLimit: Int # $productFind: ProductQueryParam
	) {
		getAllBrands(
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on Brand {
								_id
								name
								profileImage
								address {
									address1
									countryLabel
									countryCode
									city
									address1
								}
								phoneNumber
								email
								rating
								additionalMedia
								description
								products(
									populate: ["images"]
									find: { publishedStatus: "ACTIVE" }
									current: $productCurrent
									limit: $productLimit
								) {
									...BrandProductsWithTotal
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_SINGLE_BRAND = gql`
	${BRAND_PRODUCTS_WITH_TOTAL}
	query getBrand($_id: ID!, $productCurrent: Int, $productLimit: Int) {
		getBrand(_id: $_id) {
			statusCode
			success
			message
			data {
				... on Brand {
					_id
					name
					profileImage
					address {
						address1
						countryLabel
						countryCode
						city
						address1
					}
					phoneNumber
					email
					rating
					additionalMedia
					description
					products(
						current: $productCurrent
						limit: $productLimit
						populate: ["images", "brand"]
						find: { publishedStatus: "ACTIVE" }
					) {
						...BrandProductsWithTotal
					}
				}
			}
		}
	}
`;

export const GET_BRAND_STATS = gql`
	query getBrandStats($_ids: [ID!]) {
		getBrandStats(brandIds: $_ids) {
			statusCode
			success
			data {
				total
				data {
					... on BrandStats {
						brandId
						followers {
							sellersCount
							guestCount
							hostCount
						}
						eventsCount
						followersCount
						productsCount
					}
				}
			}
		}
	}
`;

export const GET_AFFILIATED_BRAND = gql`
	${GET_ALL_LIVE_EVENTS_FRAGMENT}
	${GET_ALL_PHYSICAL_EVENTS_FRAGMENT}
	query getEventsAffiliatedWithBrand($brandId: ID!) {
		getEventsAffiliatedWithBrand(brandId: $brandId, current: 0, limit: 10) {
			success
			data {
				... on All {
					total
					data {
						...PhysicalEventFragment
						...LiveEventFragment
					}
				}
			}
		}
	}
`;

export const GET_BRAND_BY_TAG = gql`
	${BRAND_FRAGMENT}
	query filterBrandsByTag($tag: String) {
		filterBrandsByTag(tag: $tag) {
			statusCode
			success
			message
			data {
				... on All {
					data {
						...BrandFragment
					}
				}
			}
		}
	}
`;

export const GET_FOLLOWING_BRANDS = gql`
	${BRAND_FRAGMENT}
	query getFollowingBrands(
		$current: Int
		$limit: Int
		$find: BrandQueryParam
		$sort: JSON
	) {
		getFollowingBrands(
			current: $current
			limit: $limit
			find: $find
			sort: $sort
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...BrandFragment
						}
					}
				}
			}
		}
	}
`;

export const GET_ALL_STARTER_KITS = gql`
	query getAllStarterKits(
		$find: StarterKitQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
	) {
		getAllStarterKits(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				"brand"
				{
					path: "variants"
					model: "Variant"
					populate: [{ path: "associatedProduct", model: "Product" }]
				}
			]
		) {
			statusCode
			success
			data {
				total
				data {
					... on StarterKit {
						_id
						name
						description
						starterKitId
						inventory {
							quantity
						}
						variants {
							_id
							image {
								src
							}
							option {
								values {
									price
									name
								}
							}
							associatedProduct {
								_id
								name
								shortDescription
							}
						}
						brand {
							_id
							name
						}
						vat
						price
						compareAtPrice
					}
				}
			}
		}
	}
`;

export const GET_STARTERKIT_DETAILS = gql`
	query getStarterKit($_id: String) {
		getStarterKit(
			_id: $_id
			populate: [
				"brand"
				{
					path: "variants"
					model: "Variant"
					populate: [{ path: "associatedProduct", model: "Product" }]
				}
			]
		) {
			statusCode
			success
			data {
				... on StarterKit {
					_id
					name
					description
					price
					vat
					compareAtPrice
					brand {
						_id
						name
						profileImage
					}
					variants {
						_id
						name
						image {
							src
						}
						associatedProduct {
							shortDescription
							description
							_id
							name
						}
					}
				}
			}
		}
	}
`;

export const GET_NEW_BRANDS = gql`
	${BRAND_FRAGMENT}
	query getNewBrands($current: Int, $limit: Int, $sort: JSON) {
		getNewBrands(current: $current, limit: $limit, sort: $sort) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							...BrandFragment
						}
					}
				}
			}
		}
	}
`;
export const GET_BRANDS_SELECTED_FOR_YOU = gql`
	${BRAND_FRAGMENT}
	query getBrandsSelectedForYou($limit: Int, $current: Int) {
		getBrandsSelectedForYou(
			distance: 100
			current: $current
			limit: $limit
		) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						...BrandFragment
					}
				}
			}
		}
	}
`;
